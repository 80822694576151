/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React from 'react'
import { cssRaw } from 'typestyle'

/* Components =================================================================================== */
import Router from './router'
import { Notification } from './components/notification'
import { GlobalContextProvider } from './contexts/global-context'

import config from '../config'

cssRaw(`
@font-face {
  font-family: GT America;  
  src: url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Regular.woff2') format('woff2'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Regular.woff') format('woff'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Regular.ttf') format('truetype'); 
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: GT America;  
  src: url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Regular-Italic.woff2') format('woff2'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Regular-Italic.woff') format('woff'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Regular-Italic.ttf') format('truetype'); 
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: GT America;  
  src: url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Medium.woff2') format('woff2'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Medium.woff') format('woff'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Medium.ttf') format('truetype'); 
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: GT America;  
  src: url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Medium-Italic.woff2') format('woff2'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Medium-Italic.woff') format('woff'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Medium-Italic.ttf') format('truetype'); 
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: GT America;  
  src: url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Bold.woff2') format('woff2'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Bold.woff') format('woff'), url('https://cdn.aeki.dev/public/fonts/gt-america/GT-America-Standard-Bold.ttf') format('truetype'); 
  font-weight: 700;
  font-style: normal;
}

html, body {
  height: 100%;
  font-family: ${config.fonts.primary};
}

body {
  background: ${config.theme[0].baselines.background.color.light};
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: initial;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

[type=submit], [type=reset], button, html [type=button] {
  -webkit-appearance: initial;
}
`)

/* <App /> ====================================================================================== */
function App(props: any) {
  const { state, cookies } = props

  return (
    <GlobalContextProvider cookies={cookies}>
      <Router state={state} />
      <Notification.render />
    </GlobalContextProvider>
  )
}

export default App
