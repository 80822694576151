/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { FC, useContext, useEffect } from 'react'
import { classes, style, stylesheet } from 'typestyle'

import { TOPBAR_HEIGHT } from './app-layout.constants'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
import { AppContext } from './app-layout.controller'
import { Box } from '../box/box.component'

export const Topbar: FC<{
  children?: any
  context: React.Context<AppContext>
}> = ({ context, children }) => {
  const appLayoutContext = useContext(context)
  const { $components, config, getOffsetTop } = appLayoutContext
  const [active, $active] = useBoolean(config.topbar.active)

  useEffect(() => {
    $components.patch('topbar', { active, $active })
  }, [])

  useEffect(() => {
    $components.patch('topbar', { active, $active })
  }, [active])

  return (
    <Box
      className={classes(
        STYLES.container,
        style({
          top: active ? 0 : -TOPBAR_HEIGHT,
        }),
      )}
    >
      {children}
    </Box>
  )
}

const STYLES = stylesheet({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 6,
    height: TOPBAR_HEIGHT,
  },
})
