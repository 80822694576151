/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { useRef, useEffect } from 'react'
import { classes, style } from 'typestyle'

/* Styles ======================================================================================= */
import { floatDialogClass } from './float-dialog.class'
import useValue from '../../hooks/use-value'

/* Types ======================================================================================== */
import { ComponentCornerPositionType } from '../__core/component.types'
import { Box } from '../box/box.component'

type FloatDialogProps = {
  style?: React.CSSProperties
  children?: any
  anchorPosition: ComponentCornerPositionType
  dialogPosition: ComponentCornerPositionType
  fullWidth?: boolean
  offsetY?: number
  onClick?: any
  open?: boolean
  parentRect: any
  parentRef: any
  width?: number
  fixed?: boolean
}

const defaultProps: {} = {}

/* <FloatDialog /> ============================================================================== */
const FloatDialogComponent: React.FC<FloatDialogProps> = props => {
  const {
    children,
    anchorPosition,
    dialogPosition,
    fullWidth,
    onClick,
    open,
    parentRef,
    fixed,
  } = props

  const dialogRef = useRef(null)
  const contentRef = useRef(null)
  const [time] = useValue(Date.now)
  const [internalChildren] = useValue(null)
  const [internalOffsetX, $internalOffsetX] = useValue(null)
  const [internalOffsetY, $internalOffsetY] = useValue(null)
  const [internalOpacity, $internalOpacity] = useValue(false)
  const [internalWidth, $internalWidth] = useValue(0)
  const [parentRectClientRect, $parentRectClientRect] = useValue(null)

  let timeout: any
  useEffect(() => {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      $internalOpacity.set(false)
      // Timeout is needed because of unstable behaviour of content not loading initially to get size for compute
      if (parentRef.current && contentRef.current) {
        const contentRect = (contentRef.current as any).getBoundingClientRect()
        const parentRect = (parentRef.current as any).getBoundingClientRect()

        $parentRectClientRect.set(parentRect)

        $internalWidth.set(parentRect.width)

        const parsedAnchorPosition = anchorPosition.split(' ')
        const parsedDialogPosition = dialogPosition.split(' ')
        /**
         * pad number when calculating for overflown
         */
        const pad = 8
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        /**
         * default pad when floating element is close to the sides/top/bottom
         */
        const offset = 8
        const scrollY = window ? window.scrollY : 0

        let x = 0
        let y = fixed ? scrollY * -1 : 0 // 0 // -parentRect.height :

        /* Compute anchor positions starts */
        switch (parsedAnchorPosition[0]) {
          case 'top':
            y = y
            break
          case 'bottom':
            // console.log('here', parentRect.height, scrollY)
            // y -= scrollY
            // y = y + parentRect.height - scrollY
            break
        }

        switch (parsedAnchorPosition[1]) {
          case 'left':
            x = 0
            break
          case 'center':
            x = parentRect.width / 2
            break
          case 'right':
            x = parentRect.width
            break
        }
        /* Compute anchor positions ends */

        /* Compute dialog positions starts */
        switch (parsedDialogPosition[0]) {
          case 'top':
            if (contentRect.y + contentRect.height > clientHeight + scrollY) {
              y = y - parentRect.height - contentRect.height - 8
            } else {
              y += offset
            }
            break
          case 'bottom':
            if (parentRect.y + y < pad) {
              y = parentRect.height + offset // - scrollY
            } else {
              y -= contentRect.height
            }
            break
        }

        switch (parsedDialogPosition[1]) {
          case 'left':
            if (parsedAnchorPosition[1] === 'right') {
              if (parentRect.x + contentRect.width + x >= clientWidth - pad) {
                x = -contentRect.width + parentRect.width
              }
            } else {
              // if (parentRect.x + contentRect.width >= clientWidth - pad) {
              //   x -= contentRect.width + parentRect.x - clientWidth
              // }
            }

            break
          case 'center':
            x -= contentRect.width / 2
            break
          case 'right':
            x = x - contentRect.width
            if (parsedAnchorPosition[1] === 'right') {
              if (x + parentRect.x > clientWidth - pad) {
                x -= parentRect.width + contentRect.width + parentRect.x - clientWidth
              }

              if (parentRect.x + parentRect.width - contentRect.width - pad <= 0) {
                x -= parentRect.x + parentRect.width - contentRect.width
              }
            } else {
              if (parentRect.x + x <= 0) {
                x -= parentRect.x + x
              }
            }

            break
        }

        $internalOffsetX.set(x)
        $internalOffsetY.set(y)

        $internalOpacity.set(true)
      }
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [open])

  const { base, proped } = floatDialogClass.setProps({ open })

  return (
    <Box
      className={classes(
        'aeki-float-dialog',
        base,
        open && proped.open,
        proped.fixed,
        fullWidth && proped.fullWidth,
        internalOpacity && proped.show,
      )}
      maxWidth={fullWidth && parentRectClientRect ? parentRectClientRect.width : undefined}
      // style={fixed ? { width: internalWidth } : {}}
      onClick={onClick}
      ref={dialogRef}
      fixed
    >
      <Box
        className={style({
          top: internalOffsetY,
          left: internalOffsetX,
          minWidth: props.width || 176,
          transitionProperty: 'none',
        })}
        ref={contentRef}
        baseline={'none'}
        absolute
        fullWidth
      >
        {internalOffsetY !== undefined && internalOffsetX !== undefined && children}
      </Box>
    </Box>
  )
}

FloatDialogComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const FloatDialog = FloatDialogComponent
