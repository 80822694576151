/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
/* Routes ======================================================================================= */
import Routes from './default/routes'

const Router = (props: any) => {
  const { state } = props

  return Routes({ ...state })
}

export default Router
