/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React from 'react'
import { classes } from 'typestyle'
// import loadable from '@loadable/component'

/* Components =================================================================================== */
import { Box } from '../../../__aeki__/components/box/box.component'
import { Button } from '../../../__aeki__/components/button'
import { Dropdown } from '../../../__aeki__/components/dropdown'

/* Styles ======================================================================================= */
import { A } from '../../../__aeki__/components/a'
import { f16i } from '../../../__aeki__/styles/styleset/font-size/f16i'
import { mb4 } from '../../../__aeki__/styles/styleset/margin/mb4'
import { mr8 } from '../../../__aeki__/styles/styleset/margin/mr8'
import { p12 } from '../../../__aeki__/styles/styleset/padding/p12'
import { w100 } from '../../../__aeki__/styles/styleset/width/w100'
import { z5 } from '../../../__aeki__/styles/styleset/z-index/z5'
import { f18i } from '../../../__aeki__/styles/styleset/font-size/f18i'
import { p4 } from '../../../__aeki__/styles/styleset/padding/p4'

export const MobileMenu = (props: {
  links: { label: string; to: string; icon?: any }[]
  isScrolled?: boolean
}) => {
  const { links, isScrolled } = props

  const renderContent = () => () => {
    return (
      <Box className={classes(p4, z5)} baseline="surface" rounded shadow minWidth={176} flex row>
        {links.map((link: any, key: any) => {
          if (link.to) {
            return (
              <Button
                as={<A to={link.to} />}
                icon={
                  link.icon ? (
                    <span className={classes('material-icons-two-tone', f16i)}>{link.icon}</span>
                  ) : (
                    ''
                  )
                }
                className={classes(mr8, w100)}
                label={link.label}
                size="lg"
                baseline={link.baseline}
                square
                key={key}
              />
            )
          }
        })}
      </Box>
    )
  }

  return (
    <Dropdown
      floatProps={{
        width: 176,
        anchorPosition: 'bottom right',
        dialogPosition: 'top right',
      }}
    >
      <Button
        icon={<span className={classes('material-icons', f18i)}>menu</span>}
        baseline={isScrolled ? 'background' : 'surface'}
        ariaLabel="Toggle Menu"
        size="lg"
      />
      {renderContent()}
    </Dropdown>
  )
}
