export default {
  seo: {
    default: {
      mainImage: '',
      title: '삼일로지스틱스',
      description: '',
    },
  },
  theme: [
    {
      baselines: {
        primary: {
          color: {
            light: '#287d57',
            medium: '#407b61',
            dark: '#287d57',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        primaryAlt: {
          color: {
            light: 'black',
            medium: 'black',
            dark: 'black',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        secondary: {
          color: {
            light: 'black',
            medium: 'black',
            dark: 'black',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        secondaryAlt: {
          color: {
            light: '#FFE600',
            medium: '#FFE600',
            dark: '#FFE600',
          },
          contrast: {
            light: '#381E1E',
            medium: '#381E1E',
            dark: '#381E1E',
          },
        },
        background: {
          color: {
            light: '#edeef0',
            medium: '#edeef0',
            dark: '#edeef0',
          },
          contrast: {
            light: 'rgb(24,24,24)',
            medium: 'rgb(24,24,24)',
            dark: 'rgb(24,24,24)',
          },
        },
        backgroundAlt: {
          color: {
            light: 'rgb(30,30,30)',
            medium: 'rgb(30,30,30)',
            dark: 'rgb(30,30,30)',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        surface: {
          color: {
            light: 'white',
            medium: 'white',
            dark: 'rgb(239, 239, 239)',
          },
          contrast: {
            light: 'rgb(24,24,24)',
            medium: 'rgb(24,24,24)',
            dark: 'rgb(24,24,24)',
          },
        },
        surfaceAlt: {
          color: {
            light: 'none',
            medium: 'none',
            dark: 'rgba(255,255,255,0.1)',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        error: {
          color: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
          contrast: {
            light: 'red',
            medium: 'red',
            dark: 'red',
          },
        },
        errorAlt: {
          color: {
            light: 'rgb(217, 147, 47)',
            medium: 'rgb(217, 147, 47)',
            dark: 'rgb(217, 147, 47)',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        disabled: {
          color: {
            light: 'rgb(230,230,230)',
            medium: 'rgb(230,230,230)',
            dark: 'rgb(230,230,230)',
          },
          contrast: {
            light: 'rgb(120,120,120)',
            medium: 'rgb(120,120,120)',
            dark: 'rgb(120,120,120)',
          },
        },
        disabledAlt: {
          color: {
            light: 'rgb(72 153 166)',
            medium: 'rgb(72 153 166)',
            dark: 'rgb(72 153 166)',
          },
          contrast: {
            light: 'white',
            medium: 'white',
            dark: 'white',
          },
        },
        none: {
          color: {},
          contrast: {},
        },
      },
      boxes: {
        border: {
          light: 'rgb(240,240,240)',
          medium: 'rgb(230,230,230)',
          dark: 'rgb(220,220,220)',
        },
        shadow: {
          light: 'rgba(0,0,0,0.05)',
          medium: 'rgba(0,0,0,0.1)',
          dark: 'rgba(0,0,0,0.15)',
        },
      },
      transition: {
        slow: '0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        medium: '0.35s cubic-bezier(0.19, 1, 0.22, 1)',
        fast: '0.1s cubic-bezier(0.19, 1, 0.22, 1)',
      },
    },
  ],
  app: {
    headerProps: {
      menu: [],
    },
    navbarProps: {
      menu: [],
      padForLogo: false,
    },
  },
  fonts: {
    primary: '"Noto Sans KR", Helvetica, Arial, sans-serif',
    secondary: '"GT America", Helvetica, Arial, sans-serif',
  },
  head: [
    {
      type: 'link',
      props: {
        href:
          'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@300;400;500;600&display=swap',
        rel: 'stylesheet',
      },
    },
    {
      type: 'link',
      props: {
        href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap',
        rel: 'stylesheet',
      },
    },
  ],
}
