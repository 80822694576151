/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React from 'react'
import { stylesheet } from 'typestyle'

/* Constants ==================================================================================== */
import { HEADER } from '../../../components/app-layout/app-layout.constants'

/* Styles ======================================================================================= */
import { AppLayoutClass } from '../../../components/app-layout'

/* Components =================================================================================== */
import Footer from '../../../../components/footer'
import { Box } from '../../../components/box/box.component'
import { Header } from '../../../../components/header'
import { HEADER_PROPS } from '../../../../routes/__home__/header-props'

/* App Layout Init ============================================================================== */
const AppLayout = new AppLayoutClass()

AppLayout.setConfig({
  navbar: {
    active: false,
  },
  menubar: {
    active: false,
  },
  toolbar: {
    active: false,
  },
})

/* App Layout Components ======================================================================== */
AppLayout.setComponent(HEADER, Header)

const PageNotFound: React.FC = () => {
  return (
    <AppLayout.render
      head={{
        title: `Page Not Found - ${loc === 'ko' ? '삼일로지스틱스' : 'Samil Logistics'}`,
      }}
      headerProps={{
        ...HEADER_PROPS,
        scrolled: true,
        disableUserMenu: true,
      }}
      content={
        <Box>
          <Box className={STYLES.container}>
            <Box className={STYLES.containerTitle}>The page does not exist.</Box>
            <Box className={STYLES.containerSubtitle}>
              You may have entered the link incorrectly or the page may have been deleted/moved.
            </Box>
          </Box>
          <Footer />
        </Box>
      }
    />
  )
}

const STYLES = stylesheet({
  container: {
    minHeight: '440px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  containerTitle: {
    fontSize: '24px',
    margin: '16px',
    whiteSpace: 'nowrap',
  },
  containerSubtitle: {
    fontSize: '14px',
    color: '#a2acba',
    margin: '16px',
    whiteSpace: 'nowrap',
  },
})

export default PageNotFound
