/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { useRef, useLayoutEffect } from 'react'
import useValue from '../../__aeki__/hooks/use-value'

export const KakaoLoader: React.FC<any> = (props: {
  children: any
  component: any
  componentProps?: any
  loaderProps?: any
  className?: string
}) => {
  const { children } = props
  const [_kakao, $kakao] = useValue()
  const API_KEY = process.env.REACT_APP_KAKAO_API_KEY
  const mountedRef = useRef<boolean>(false)

  const loadScript = () => {
    return new Promise((resolve, reject) => {
      const kakaoScript = document.getElementById('kakao')

      if (!kakaoScript) {
        const script = document.createElement('script')
        script.id = 'kakao'
        script.type = 'text/javascript'
        script.addEventListener('load', function(event) {
          ;(window as any).Kakao.init(API_KEY)
          resolve((window as any).Kakao)
        })
        // console.log('hello', (window as any).Kakao, API_KEY)
        // script.onload = () => {
        // }

        script.src = 'https://developers.kakao.com/sdk/js/kakao.js'

        document.head.appendChild(script)
      } else {
        if ((window as any).Kakao) {
          resolve((window as any).Kakao)
        } else {
          kakaoScript.addEventListener('load', function(event) {
            resolve((window as any).Kakao)
          })
        }
      }
    })
  }

  useLayoutEffect(() => {
    mountedRef.current = true
    async function load() {
      const kakao = await loadScript()

      $kakao.set(kakao)
    }

    if (typeof window !== 'undefined') {
      load()
    }

    return () => {
      mountedRef.current = false
    }
  }, [$kakao])

  const Component = props.component
  /**
   * choosing either _kakao state or global Google.object insures to feed kakao api object with
   * unstable life cylce
   */

  return (
    <div className={props.className}>
      {_kakao && <Component kakao={_kakao} {...props.componentProps} />}
    </div>
  )
}
