/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import { media, style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { createCustomMediaQuery } from '../style-class/style-class.utils'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for content
   */
  base: string
  /**
   * Proped class for content
   */
  proped: {
    offsetted: string
    loaded: string
  }
}

type ClassProps = {
  leftOffset?: number
  topOffset?: number
  width?: string
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { leftOffset, topOffset, width } = props

  const base = style({
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    ...createCustomMediaQuery({
      maxWidth: 992,
      style: { width: '100% !important', marginLeft: '0px !important' },
    }),
    ...media({ type: 'print' }, {
      width: '100% !important',
      top: '0px !important',
      paddingTop: '0px !important',
    })
  })

  const proped = {
    offsetted: style({
      marginLeft: leftOffset,
      paddingTop: topOffset,
      width,
      height: '100%',
    }),
    loaded: style({
      transition: theme.transition.medium,
      transitionProperty: 'margin-left, padding-top, width',
    }),
  }

  return {
    base,
    proped,
  }
}

/* Export ======================================================================================= */
export const contentClass = new StyleClass<ClassNames, ClassProps>(getNames)
