import { useEffect } from 'react'
import { stylesheet } from 'typestyle'
import { CSSTransition } from 'react-transition-group'
import { ComponentBaselineType } from '../__core/component.types'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { Button } from '../button'
import { Text } from '../text/text.component'
import { Box } from '../box/box.component'

/* Styles ======================================================================================= */
import { mr8 } from '../../styles/styleset/margin/mr8'
import { p16 } from '../../styles/styleset/padding/p16'

/* Types ======================================================================================== */
type Notification = {
  label?: string
  baseline?: ComponentBaselineType
  alt?: boolean
}

let timeout: any

/* NotificationStack ============================================================================ */
class NotificationStack {
  count = 0
  self: any = null
  storage: Notification[] = []
  components: any = {}

  setComponents = (components: any) => {
    this.components = components
  }

  add = (name: string, component: any) => {
    this.components[name] = component
  }

  push = (dialog: Notification) => {
    this.pop()
    clearTimeout(timeout)
    this.storage[this.count + 1] = dialog
    this.count++

    if (this.count > 0) {
      timeout = setTimeout(() => {
        this.pop()
      }, 4000)
    } else {
      if (timeout) {
        clearTimeout(timeout)
      }
    }

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  pop = () => {
    delete this.storage[this.count]

    this.count--

    if (this.count < 0) {
      this.count = 0
    }

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  size = () => this.count

  render = () => {
    const [self, $self] = useValue(this)
    // const [currentNotification, $currentNotification] = useValue(this)
    this.self = $self

    useEffect(() => {
      if (self.count > 0) {
        document.documentElement.style.touchAction = 'none'
        document.body.style.touchAction = 'none'
      } else {
        document.documentElement.style.touchAction = ''
        document.body.style.touchAction = ''
      }
    }, [self])

    const handleContentClick = (event: any) => {
      event.stopPropagation()
    }

    return (
      <CSSTransition in={self.count > 0} timeout={300} classNames="popin" unmountOnExit>
        <>
          {self.storage.map((dialog: Notification, key: number) => {
            const { label, baseline = 'secondary', alt } = dialog

            return (
              <NotificationContainer key={key}>
                <NotificationContentContainer onClick={handleContentClick}>
                  <Box
                    className={p16}
                    baseline={baseline as any}
                    alt={alt}
                    rounded
                    shadow
                    flex
                    spaceBetween
                    alignCenterY
                  >
                    <Text className={mr8} semiBold>
                      {label}
                    </Text>
                    <Button
                      label="닫기"
                      size="md"
                      onClick={() => {
                        self.pop()
                      }}
                    />
                  </Box>
                </NotificationContentContainer>
              </NotificationContainer>
            )
          })}
        </>
      </CSSTransition>
    )
  }
}

/* Styled Components ============================================================================ */
const NotificationContentContainer: any = (props: any) => (
  <div className={STYLES.containerNotificationContent} {...props}>
    {props.children}
  </div>
)

export const NotificationContainer = (props: any) => (
  <div className={STYLES.containerNotification} {...props}>
    {props.children}
  </div>
)

export const NotificationActionContainer = (props: any) => (
  <div className={STYLES.containerNotificationActions} {...props}>
    {props.children}
  </div>
)

const STYLES = stylesheet({
  containerNotification: {
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
    zIndex: 11,
    top: 20,
    $nest: {
      '&.popin-enter': {
        opacity: 0,
        transform: 'translateX(-50%) translateY(-8px)',
      },

      '&.popin-enter-active': {
        opacity: 1,
        transform: 'translateX(-50%) translateY(0px)',
      },

      '&.popin-exit': {
        opacity: 1,
        transform: 'translateX(-50%) translateY(0px)',
      },

      '&.popin-exit-active': {
        opacity: 0,
        transform: 'translateX(-50%) translateY(-8px)',
      },
    },
  },
  containerNotificationContent: {
    minWidth: 296,
    padding: 8,
    borderRadius: 8,
    // position: 'fixed',
    // left: '50%',
    // transform: 'translateX(-50%)',
  },
  containerNotificationActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 8,
    $nest: {
      // [`.${ButtonCore}:last-child`]: {
      //   marginLeft: 8,
      // },
    },
  },
})

export const Notification = new NotificationStack()
