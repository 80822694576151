/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import { useState } from 'react'

export type UseValueCallbacks<X> = {
  /** Updates current value */
  readonly set: (x: any) => X
  /** Resets current value to `initialValue` */
  readonly reset: () => void
  readonly get: () => X
}

export type UseValue<TYPE, RETURN_CALLBACK, OPTIONS = {}> = (
  initialValue?: TYPE,
  options?: OPTIONS,
) => [TYPE, UseValueCallbacks<TYPE> & RETURN_CALLBACK]

/**
 * useValue
 *
 * Returns a stateful value, and functions to interact with it
 *
 * @param initialValue Initial value
 */
const useValue: UseValue<any, UseValueCallbacks<any>> = initialValue => {
  const [value, set] = useState(initialValue)
  const reset = () => {
    set(initialValue)
  }

  return [value, { set, reset, get: () => value }]
}

export default useValue
