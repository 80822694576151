/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import React, { useEffect, useCallback } from 'react'
import { classes } from 'typestyle'
import { Link } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useValue from '../../../__aeki__/hooks/use-value'

/* Services ===================================================================================== */
import { getCurrentUser, clearCurrentUser, setPreviousRoute } from '../../../__aeki__/services/auth'

/* Components =================================================================================== */
import Skeleton from '../../../__aeki__/components/skeleton'
import { Box } from '../../../__aeki__/components/box/box.component'
import { Button } from '../../../__aeki__/components/button'
import { Dropdown } from '../../../__aeki__/components/dropdown'
import { Loader } from '../../../__aeki__/components/loader'

/* Styles ======================================================================================= */
import { br42i } from '../../../__aeki__/styles/styleset/border-radius/br42i'
import { mb4 } from '../../../__aeki__/styles/styleset/margin/mb4'
import { p12 } from '../../../__aeki__/styles/styleset/padding/p12'
import { w100 } from '../../../__aeki__/styles/styleset/width/w100'
import { f16i } from '../../../__aeki__/styles/styleset/font-size/f16i'

/* <UserMenu /> ================================================================================= */
export const UserMenu = () => {
  const [currentUser, $currentUser] = useValue()

  useEffect(() => {
    const { currentUser = {} } = getCurrentUser()
    $currentUser.set(currentUser)
  }, [])

  const handleSignOut = (close: any) => () => {
    close()

    $currentUser.set(clearCurrentUser())
    const url = window.location.href.replace('auth=1&', '').replace('auth=1', '')
    window.location.replace(url)
  }

  const renderContent = useCallback(() => {
    if (currentUser && Object.keys(currentUser).length > 0 && !currentUser.notLoaded) {
      let linkToGroups = '/a/groups'

      if (currentUser.groups.length === 1) {
        linkToGroups = `/a/groups/${currentUser.groups[0].id}`
      }

      return (
        <>
          <Dropdown
            floatProps={{
              anchorPosition: 'bottom right',
              dialogPosition: 'top right',
              width: 176,
            }}
          >
            <Button
              label={`Hi, ${currentUser.firstName}`}
              ariaLabel="Toggle User Menu"
              baseline="primary"
              size="md"
              icon={
                <span className={classes('material-icons-two-tone', f16i)}>account_circle</span>
              }
            />
            {(close: any) => {
              return (
                <Box className={p12} baseline="surface" minWidth={176} rounded shadow flex row>
                  <Button
                    as={<Link to="/a/user/page/user-profile" />}
                    className={classes(mb4, w100)}
                    baseline="background"
                    label="My Profile"
                    size="sm"
                    icon={
                      <span className={classes('material-icons-two-tone', f16i)}>account_box</span>
                    }
                    square
                  />

                  {currentUser.groups && currentUser.groups.length > 0 ? (
                    <Button
                      as={<Link to={currentUser.groups.length === 1 ? `/a/groups/${currentUser.groups[0].id}` : "/a/groups"} />}
                      className={classes(mb4, w100)}
                      baseline="background"
                      label="My Group(s)"
                      size="sm"
                      icon={<span className={classes('material-icons-two-tone', f16i)}>group</span>}
                      square
                    />
                  ) : (
                    ''
                  )}
                  <Button
                    label={'Sign Out'}
                    size="sm"
                    baseline="secondary"
                    onClick={handleSignOut(close)}
                    square
                  />
                </Box>
              )
            }}
          </Dropdown>
        </>
      )
    }
    return (
      <>
        <Button
          as={<Link to="/a/signin" title="Go sign in" />}
          onClick={() => {
            setPreviousRoute()
          }}
          label="Sign in"
          size="sm"
          baseline="primary"
          icon={<span className={classes('material-icons-two-tone', f16i)}>account_circle</span>}
        />
      </>
    )
  }, [currentUser])

  return (
    <Loader
      timeout={1}
      loaded={true}
      skeleton={<Skeleton width={85} height={32} variant="circle" className={br42i} />}
      component={renderContent()}
    />
  )
}
