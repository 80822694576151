/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import React, { createContext, FC } from 'react'

import { DefaultTheme } from '../theme/theme.default'
import { useTheme } from '../theme'

export class StyleClass<STYLE_TYPE extends {}, STYLE_PROPS extends {}> {
  getNames: (props: STYLE_PROPS, theme?: any) => STYLE_TYPE
  names: STYLE_TYPE
  context: any

  constructor(names: any) {
    this.getNames = names
    this.names = names({ baseline: 'surface', size: 'md' }, DefaultTheme)
    this.context = createContext({ names })
  }

  setProps: (props: STYLE_PROPS) => STYLE_TYPE = props => {
    const theme: any = useTheme()
    this.names = this.getNames({ baseline: 'surface', size: 'md', ...props }, theme)

    return this.names
  }

  render: FC<{ children: any }> = ({ children }) => {
    return <this.context.Consumer>{children}</this.context.Consumer>
  }
}
