/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import * as serviceWorker from './__aeki__/service-worker'
import App from './__aeki__/app'

import { setStylesTarget, forceRenderStyles, cssRaw } from 'typestyle'
import { normalize } from 'csstips'
import { loadableReady } from '@loadable/component'

loadableReady(() => {
  setStylesTarget(document.getElementById('styles-target') as any)
  forceRenderStyles()
  normalize()

  cssRaw(' body { word-break:  keep-all; }')

  ReactDOM.hydrate(
    <BrowserRouter forceRefresh={false}>
      <App />
    </BrowserRouter>,
    document.getElementById('root'),
  )
})

serviceWorker.unregister()
