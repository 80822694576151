import React, { useRef } from 'react'
import { Button } from '../../__aeki__/components/button'

export const KakaoChannelButton = (props: any) => {
  const { kakao, componentProps } = props

  return (
    <Button
      // square
      baseline="secondary"
      // label='톡상담'
      alt
      icon={
        <svg style={{ width: 24 }} viewBox="0 0 70 70">
          <defs>
            <filter
              id="luminosity-noclip"
              x="0"
              y="-8284.41"
              style={{ width: 10, height: 10 }}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodColor="#fff" result="bg" />
              <feBlend in="SourceGraphic" in2="bg" />
            </filter>
            <mask id="mask" x="0" y="-8284.41" width="70" height="32766" maskUnits="userSpaceOnUse">
              <g className="cls-4" />
            </mask>
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="_π_ÃæÓ_1" data-name="∑π¿ÃæÓ_1">
              <g className="cls-1">
                <path
                  style={{ fill: 'none' }}
                  className="cls-2"
                  d="M5.6,0H64.39A5.6,5.6,0,0,1,70,5.6V64.39A5.61,5.61,0,0,1,64.39,70H5.61A5.61,5.61,0,0,1,0,64.39V5.6A5.6,5.6,0,0,1,5.6,0Z"
                />
              </g>
              <path
                style={{ fill: '#381E1E' }}
                className="cls-3"
                d="M35,10.91c-15.19,0-27.49,9.73-27.49,21.73,0,7.81,5.21,14.66,13,18.49-.58,2.15-2.08,7.78-2.38,9-.38,1.49.54,1.47,1.15,1.07L29.9,54a34,34,0,0,0,5.1.38c15.18,0,27.49-9.73,27.49-21.74S50.18,10.91,35,10.91"
              />
            </g>
          </g>
        </svg>
      }
      onClick={() => {
        kakao.Channel.addChannel({
          channelPublicId: '_xctmYs', // 카카오톡 채널 홈 URL에 명시된 id로 설정합니다.
        })
      }}
      {...componentProps}
    />
  )
}
