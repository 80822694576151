/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { FC, useContext, useEffect } from 'react'
import { classes } from 'typestyle'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Constants ==================================================================================== */
import { TOPBAR_HEIGHT, HEADER_HEIGHT, NAVBAR_WIDTH, TOOLBAR } from './app-layout.constants'

/* Styles ======================================================================================= */
import { toolbarClass } from './toolbar.class'

/* Components =================================================================================== */
import { AppContext } from './app-layout.controller'

/* Types ======================================================================================== */
type ToolbarProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  context: React.Context<AppContext>
}

const defaultProps: {} = {}

/* <Toolbar /> ================================================================================= */
const ToolbarComponent: React.FC<ToolbarProps> = props => {
  const { className, children, style, context } = props
  const appLayoutContext = useContext(context)
  const { config, components, $components } = appLayoutContext
  const { topbar, header, navbar } = components
  const [active, $active] = useBoolean(config.toolbar.active)
  const [loaded, $loaded] = useBoolean(false)
  const show = () => $active.set(true)
  const hide = () => $active.set(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      $loaded.set(true)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    $components.patch(TOOLBAR, {
      active,
      $active,
      visible: active,
      show,
      hide,
    })
  }, [active])

  const getLeftOffset = () => {
    let left = 0

    if (navbar && navbar.active && navbar.visible) {
      left += NAVBAR_WIDTH
    }

    return left
  }

  const getTopOffset = () => {
    let top = 0

    if (topbar && topbar.active) {
      top += TOPBAR_HEIGHT
    }

    if (header && header.active && header.visible) {
      top += HEADER_HEIGHT
    }

    return top
  }

  const { base, proped } = toolbarClass.setProps({
    leftOffset: getLeftOffset(),
    topOffset: getTopOffset(),
  })

  return (
    <div
      className={classes(
        'aeki-toolbar',
        base,
        active && proped.active,
        proped.offsetted,
        loaded && proped.loaded,
        className,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

ToolbarComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Toolbar = ToolbarComponent
