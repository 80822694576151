/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { color, important } from 'csx'
import { style } from 'typestyle'
import config from '../../../config'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { createMediaQuery } from '../style-class/style-class.utils'

/* Component===================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

/* Types ======================================================================================== */
export type ClassNames = {
  component: string
  /**
   * Base class for the most parent text element
   */
  base: string

  /**
   * Base class for secondary font family
   */
  baseSecondary: string

  /**
   * Sized extension class for text element
   */
  baseSized: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
  }

  /**
   * Themed extension class for text element
   */
  baseThemed: string

  /**
   * Themed extension class for semi-bolded text
   */
  baseSemiBold: string

  /**
   * Themed extension class for bolded text
   */
  baseBold: string

  /**
   * Themed extension class for italic text
   */
  baseItalic: string

  /**
   * Themed extension class for link text
   */
  baseLink: string

  /**
   * Themed extension class for sublink text
   */
  baseSublink: string

  /**
   * Themed extension class for larger spacing
   */
  baseReadable: string

  /**
   * Themed extension class for upper-cased text
   */
  baseUppercase: string

  basePreWrap: string
  basePreLine: string
  baseSub: string
  baseNoLineHeight: string
}

export type ClassProps = {
  alt?: boolean
  baseline?: ComponentBaselineType
  crank?: boolean
  sub?: string
}

export const BASE_SIZE = 16
export const TEXT_RATIO = 0.125
const sizes = {
  xl: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 2,
  lg: BASE_SIZE + BASE_SIZE * TEXT_RATIO,
  md: BASE_SIZE,
  sm: BASE_SIZE - BASE_SIZE * TEXT_RATIO,
  xs: BASE_SIZE - BASE_SIZE * TEXT_RATIO * 2,
}

export const createGetNames: (sizes: {
  xl: number
  lg: number
  md: number
  sm: number
  xs: number
}) => (props: ClassProps, theme: any) => ClassNames = sizes => (props, theme) => {
  const { baseline, alt, crank, sub } = props

  const base = style({
    position: 'relative',
    fontFamily: config.fonts.primary,
  })

  const baseSecondary = style({
    fontFamily: config.fonts.secondary,
  })

  const component = style({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  })

  let crankValue = 1

  if (crank) {
    crankValue = 1.5
  }

  const createSub = (size: any, temp: any = 0.3) =>
    sub && {
      '&::after': {
        content: `"${sub}"`,
        position: 'absolute',
        top: '0%',
        fontSize: size * temp,
      },
    }

  const baseSized = {
    xl: style({
      fontSize: sizes.xl * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      ...createSub(sizes.xl * crankValue),
      $nest: {
        ...createMediaQuery.md({
          ...createSub(sizes.xl * 0.95 * crankValue),
          fontSize: sizes.xl * 0.95 * crankValue,
        }),
        ...createMediaQuery.sm({
          ...createSub(sizes.xl * 0.9 * crankValue),
          fontSize: sizes.xl * 0.9 * crankValue,
        }),
        ...createMediaQuery.xs({
          ...createSub(sizes.xl * 0.85 * crankValue),
          fontSize: sizes.xl * 0.85 * crankValue,
        }),
      },
    }),
    lg: style({
      fontSize: sizes.lg * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: {
        ...createSub(sizes.lg * crankValue, 0.4),
        ...createMediaQuery.md({
          ...createSub(sizes.lg * crankValue, 0.4),
          fontSize: sizes.lg * 0.95 * crankValue,
        }),
        ...createMediaQuery.sm({
          ...createSub(sizes.lg * crankValue, 0.4),
          fontSize: sizes.lg * 0.9 * crankValue,
        }),
        ...createMediaQuery.xs({
          ...createSub(sizes.lg * crankValue, 0.4),
          fontSize: sizes.lg * 0.85 * crankValue,
        }),
      },
    }),
    md: style({
      fontSize: sizes.md * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: {
        ...createMediaQuery.md({
          fontSize: sizes.md * 0.95 * crankValue,
        }),
        ...createMediaQuery.sm({
          fontSize: sizes.md * 0.9 * crankValue,
        }),
        ...createMediaQuery.xs({
          fontSize: sizes.md * 0.85 * crankValue,
        }),
      },
    }),
    sm: style({
      fontSize: sizes.sm * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: {
        ...createSub(sizes.sm * crankValue, 0.45),
        ...createMediaQuery.md({
          ...createSub(sizes.sm * crankValue, 0.45),
          fontSize: sizes.sm * 0.95 * crankValue,
        }),
        ...createMediaQuery.sm({
          ...createSub(sizes.sm * crankValue, 0.45),
          fontSize: sizes.sm * 0.9 * crankValue,
        }),
        ...createMediaQuery.xs({
          ...createSub(sizes.sm * crankValue, 0.45),
          fontSize: sizes.sm * 0.85 * crankValue,
        }),
      },
    }),
    xs: style({
      fontSize: sizes.xs * crankValue,
      fontWeight: 400,
      lineHeight: 1.4,
      $nest: {
        ...createMediaQuery.md({
          fontSize: sizes.xs * 0.95 * crankValue,
        }),
        ...createMediaQuery.sm({
          fontSize: sizes.xs * 0.9 * crankValue,
        }),
        ...createMediaQuery.xs({
          fontSize: sizes.xs * 0.85 * crankValue,
        }),
      },
    }),
  }

  const baseThemed = style({
    color: theme.getBaseline(baseline, alt).contrast.medium,
  })

  const baseSemiBold = style({
    fontWeight: important(500),
  })

  const baseBold = style({
    fontWeight: important(700),
  })

  const baseItalic = style({
    fontStyle: 'italic',
  })

  const baseLink = style({
    cursor: 'pointer',
    color: '#5690cd !important',
    transition: 'color 0.1s ease-in-out',
    $nest: {
      // '*': {
      //   color: '#767886 !important',
      // },
      '&:hover': {
        color: '#3979ca !important',
      },
    },
  })

  const baseSublink = style({
    cursor: 'pointer',
    color: 'rgb(160,160,160) !important',
    transition: 'color 0.1s ease-in-out',
    $nest: {
      '*': {
        color: '#6599da !important',
      },
      '&:hover': {
        color: '#3979ca !important',
      },
    },
  })

  const baseSub = style({
    cursor: 'pointer',
    color: '#767886 !important',
    transition: 'color 0.1s ease-in-out',
  })

  const baseUppercase = style({
    textTransform: 'uppercase',
  })

  const basePreWrap = style({
    whiteSpace: 'pre-wrap',
  })

  const basePreLine = style({
    whiteSpace: 'pre-line',
  })

  const baseReadable = style({
    lineHeight: '1.6 !important',
  })

  const baseNoLineHeight = style({
    lineHeight: '0 !important',
  })

  return {
    component,
    base,
    baseSecondary,
    baseSized,
    baseThemed,
    baseSemiBold,
    baseBold,
    baseItalic,
    baseLink,
    baseUppercase,
    basePreWrap,
    basePreLine,
    baseSublink,
    baseReadable,
    baseSub,
    baseNoLineHeight,
  }
}

export const textClass = new StyleClass<ClassNames, ClassProps>(createGetNames(sizes))
