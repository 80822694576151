/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/* Constants ==================================================================================== */
export const HEADER = 'header'
export const HEADER_HEIGHT = 60
export const NAVBAR = 'navbar'
export const NAVBAR_WIDTH = 248
export const SIDEBAR = 'sidebar'
export const SIDEBAR_MIN = 16
export const SIDEBAR_WIDTH = 320
export const TOOLBAR = 'toolbar'
export const TOOLBAR_HEIGHT = 40
export const TOPBAR = 'topbar'
export const TOPBAR_HEIGHT = 48
export const CONTENT = 'content'
