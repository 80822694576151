/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { useEffect, useRef } from 'react'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
import { boxClass } from './box.class'
import { ComponentBaselineType } from '../__core/component.types'

/* Types ======================================================================================== */
export type BoxProps = {
  ref?: any
  as?: any

  style?: React.CSSProperties
  children?: any
  id?: string
  className?: any

  onClick?: any
  onFocus?: any
  onBlur?: any
  onMouseEnter?: any
  onMouseLeave?: any
  onScroll?: any

  baseline?: ComponentBaselineType
  onlyContrast?: boolean
  alt?: boolean

  alignLeft?: boolean
  alignRight?: boolean
  alignBottom?: boolean
  alignTop?: boolean
  alignCenter?: boolean
  alignCenterX?: boolean
  alignCenterY?: boolean
  spaceBetween?: boolean
  farRight?: boolean
  farLeft?: boolean
  farTop?: boolean
  farBottom?: boolean

  center?: boolean
  centerX?: boolean
  centerY?: boolean
  centerAutoX?: boolean
  centerAutoY?: boolean
  centerAbsolute?: boolean
  centerAbsoluteX?: boolean
  centerAbsoluteY?: boolean

  fullWidth?: boolean
  fullHeight?: boolean
  halfWidth?: boolean
  halfHeight?: boolean

  relative?: boolean
  sticky?: boolean
  absolute?: boolean
  fixed?: boolean
  flex?: boolean
  flexWrap?: boolean

  maxWidth?: number | string
  minWidth?: number | string
  maxHeight?: number | string
  minHeight?: number | string

  scroll?: boolean
  border?: boolean
  borderContrast?: 'light' | 'medium' | 'dark'
  shadow?: boolean
  shadowContrast?: 'light' | 'medium' | 'dark'
  rounded?: boolean
  overflow?: boolean
  row?: boolean
  column?: boolean
  backgroundImage?: string

  focused?: boolean
}

const defaultProps: {
  baseline?: ComponentBaselineType
  onlyContrast?: boolean
  borderContrast?: 'light' | 'medium' | 'dark'
  shadowContrast?: 'light' | 'medium' | 'dark'
} = {
  baseline: 'surface',
  onlyContrast: false,
  borderContrast: 'medium',
  shadowContrast: 'medium',
}

/* <Box /> ================================================================================= */
const BoxComponent: React.FC<BoxProps> = React.forwardRef((props, ref) => {
  const {
    as,
    id,
    className,
    onClick,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    children,
    style,
    alt,
    baseline,
    onlyContrast,
    fullWidth,
    fullHeight,
    halfWidth,
    halfHeight,
    border,
    borderContrast,
    rounded,
    shadow,
    shadowContrast,
    centerX,
    centerY,
    centerAbsoluteX,
    centerAbsoluteY,
    alignLeft,
    alignRight,
    alignCenterX,
    alignCenterY,
    alignBottom,
    alignTop,
    spaceBetween,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    row,
    column,
    flex,
    sticky,
    relative,
    absolute,
    fixed,
    scroll,
    farRight,
    farLeft,
    farBottom,
    farTop,
    centerAutoX,
    centerAutoY,
    backgroundImage,
    onScroll,
    focused,
    flexWrap,
  } = props

  const { base, flexed, themed, boxed } = boxClass.setProps({
    alt,
    baseline,
    onlyContrast,

    flexWrap,
    fullWidth,
    fullHeight,
    halfWidth,
    halfHeight,

    border,
    borderContrast,
    rounded,
    shadow,
    shadowContrast,
    centerX,
    centerY,
    centerAbsoluteX,
    centerAbsoluteY,
    alignLeft,
    alignRight,
    alignCenterX,
    alignCenterY,
    alignBottom,
    alignTop,
    spaceBetween,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    row,
    column,
    flex,
    sticky,
    relative,
    absolute,
    fixed,
    scroll,
    farRight,
    farLeft,
    farBottom,
    farTop,
    centerAutoX,
    centerAutoY,
    backgroundImage,
  })

  const innerRef = useRef()

  useEffect(() => {
    if (innerRef.current as any) {
      if (focused) {
        ; (innerRef.current as any).focus()
      }
    }
  }, [focused])

  if (as) {
    return React.cloneElement(as, {
      id,
      ref: ref || innerRef,
      className: classes('aeki-box', base, themed, flexed, boxed, className),
      children,
      onClick,
      style,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      ...as.props,
    })
  }

  return (
    <div
      className={classes('aeki-box', base, themed, flexed, boxed, className)}
      id={id}
      onClick={onClick}
      style={style}
      onBlur={onBlur}
      ref={(ref as any) || innerRef}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-highlight={(props as any)['data-highlight']}
      onScroll={onScroll}
    >
      {children}
    </div>
  )
})

BoxComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Box = BoxComponent
