/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/* Types ======================================================================================== */
import { ComponentSizeType } from '../__core/component.types'

/* Constants ==================================================================================== */
import { buttonSizes } from './button.constants'

/**
 * @param size xs | sm | md | lg | xl
 */
export const getBoxSize: (size?: ComponentSizeType) => number = (size = 'md') => {
  return size && buttonSizes[size].boxSize
}

/**
 *
 * @param size
 * @param iconPosition
 * @param hasIcon
 */
export const getButtonSize: (
  size?: ComponentSizeType,
  iconPosition?: 'left' | 'right',
  hasIcon?: boolean,
) => any = (size = 'md', iconPosition = 'left', hasIcon) => {
  const { fontSize, boxSize, iconSize, iconLeft, iconRight, shrink } = buttonSizes[size]
  const padding: any = {}

  return {
    fontSize,
    height: boxSize,
    minWidth: `${boxSize}px`,
    borderRadius: `${boxSize}px`,
    $nest: {
      i: {
        fontSize: iconSize,
        height: boxSize,
        lineHeight: `${boxSize}px`,
        width: boxSize,
      },
      '&:::after': padding,
    },
  }
}
