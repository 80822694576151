/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { FC, useEffect, useCallback } from 'react'
import { classes, style } from 'typestyle'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../../__aeki__/components/__core/component.types'

/* Hooks ======================================================================================== */
import useValue from '../../__aeki__/hooks/use-value'

/* Components =================================================================================== */
import { A } from '../../__aeki__/components/a'
import { Box } from '../../__aeki__/components/box/box.component'
import { Button } from '../../__aeki__/components/button'
import { Logo } from '../logo'
import { MobileMenu } from './components/mobile-menu'
import { UserMenu } from './components/user-menu'

/* Styles ======================================================================================= */
import { f16i } from '../../__aeki__/styles/styleset/font-size/f16i'
import { h100 } from '../../__aeki__/styles/styleset/height/h100'
import { hide } from '../../__aeki__/styles/styleset/hide/hide'
import { hidesmi } from '../../__aeki__/styles/styleset/hide/hidesmi'
import { ml8 } from '../../__aeki__/styles/styleset/margin/ml8'
import { mr16 } from '../../__aeki__/styles/styleset/margin/mr16'
import { mr8 } from '../../__aeki__/styles/styleset/margin/mr8'
import { pl16 } from '../../__aeki__/styles/styleset/padding/pl16'
import { pr16 } from '../../__aeki__/styles/styleset/padding/pr16'
import { shadow as shadowCls } from '../../__aeki__/styles/styleset/shadow/shadow'
import { showsm } from '../../__aeki__/styles/styleset/show/showsm'
import { w100 } from '../../__aeki__/styles/styleset/width/w100'
import { flexsm } from '../../__aeki__/styles/styleset/flex/flexsm'
import { pl20 } from '../../__aeki__/styles/styleset/padding/pl20'
import { pr20 } from '../../__aeki__/styles/styleset/padding/pr20'
import { mr12 } from '../../__aeki__/styles/styleset/margin/mr12'
import { mr8sm } from '../../__aeki__/styles/styleset/margin/mr8sm'
import { KakaoLoader } from '../kakao'
import { KakaoChannelButton } from '../kakao-channel-button'
import { useLocation, useParams } from 'react-router-dom'
import { Text } from '../../__aeki__/components/text/text.component'

const loc = 'en'
const locale = {
  company: {
    en: 'About us',
    ko: '회사소개',
  },
  services: {
    en: 'Services',
    ko: '사업영역',
  },
  contactUs: {
    en: 'Contact Us',
    ko: '문의하기',
  },
}

/* App Layout Init ============================================================================== */
export const MAX_WIDTH = 1400
/* <Header /> =================================================================================== */
export const Header: FC<{
  context?: any
  shadow?: any
  maxWidth?: number
  menu?: any
  mobileMenu?: any
  userMenu?: any
  disableUserMenu?: boolean
  baseline?: ComponentBaselineType
  scrollEffect?: boolean
  scrolled?: boolean
}> = _props => {
  const [_maxWidth, $maxWidth] = useValue(MAX_WIDTH)
  const { loc = 'ko' } = useParams<{ loc: 'en' | 'ko' }>()

  const props = {
    ..._props,
    shadow: false,
    baseline: 'surface',
    disableUserMenu: true,
    scrollEffect: true,
    toggled: false,
    menu: [
      {
        label: locale.company[loc],
        to: `${loc === 'ko' ? '' : `/${loc}`}/a/company`,
        baseline: 'none',
        buttonProps: (scrolled: boolean) => {
          if (scrolled) {
            return {
              className: classes(style({ color: 'black !important' }), mr12),
            }
          }

          return {
            className: classes(style({ color: 'white !important' }), mr12),
          }
        },
      },
      {
        label: locale.services[loc],
        to: `${loc === 'ko' ? '' : `/${loc}`}/a/services`,
        baseline: 'none',
        buttonProps: (scrolled: boolean) => {
          if (scrolled) {
            return {
              className: mr12,
            }
          }

          return {
            className: classes(style({ color: 'white !important' }), mr12),
          }
        },
      },
      {
        label: locale.contactUs[loc],
        to: `${loc === 'ko' ? '' : `/${loc}`}/a/contact-us`,
        // isPrimary: true,
        baseline: 'primary',
        buttonProps: (scrolled: boolean) => {
          if (scrolled) {
            return {
              square: false,
              className: classes(mr12, mr8sm),
            }
          }

          return {
            square: false,
            className: classes(style({ color: 'white !important' }), classes(mr12, mr8sm)),
          }
        },
      },
      {
        isPrimary: true,
        component: <KakaoLoader component={KakaoChannelButton} />,
      },
    ],
  }
  const [scrolled, $scrolled] = useValue(props.scrolled)
  const { shadow = true, maxWidth, baseline = 'surface', menu = [], disableUserMenu } = props

  useEffect(() => {
    if (maxWidth) {
      $maxWidth.set(maxWidth)
    }
  }, [_maxWidth])

  const renderLinks = useCallback(
    (isPrimary?: boolean) => {
      return menu
        .filter((a: any) => {
          if (isPrimary) {
            return a.isPrimary
          }

          return true
        })
        .map((item: any, key: any) => {
          if (item.onClick || (item.buttonProps && item.buttonProps.onClick)) {
            return (
              <Button
                key={key}
                onClick={item.onClick}
                className={classes(mr16)}
                icon={
                  item.icon ? (
                    <span className={classes('material-icons-two-tone', f16i)}>{item.icon}</span>
                  ) : (
                    ''
                  )
                }
                label={item.label}
                size="lg"
                baseline={item.baseline || 'background'}
                square
                {...item.buttonProps(scrolled)}
              />
            )
          }

          if (item.component) {
            return React.cloneElement(item.component, { key })
          }

          if (item.buttonProps) {
            return (
              <Button
                key={key}
                as={<A to={item.to} title={item.label} />}
                className={classes(mr8)}
                icon={
                  item.icon ? (
                    <span className={classes('material-icons-two-tone', f16i)}>{item.icon}</span>
                  ) : (
                    ''
                  )
                }
                label={item.label}
                size="lg"
                baseline={item.baseline || 'background'}
                square
                {...item.buttonProps(scrolled)}
              />
            )
          }
        })
    },
    [scrolled],
  )

  const location = useLocation()

  return (
    <Box
      baseline={props.scrollEffect ? (scrolled ? baseline : 'none') : baseline}
      className={
        props.scrollEffect ? (scrolled ? `${shadow && shadowCls}` : '') : `${shadow && shadowCls}`
      }
      fullWidth
      fullHeight
    >
      <Box baseline="none" fullWidth fullHeight maxWidth={maxWidth || MAX_WIDTH} centerAutoX>
        <Box
          baseline="none"
          flex
          alignCenterY
          className={pr20}
          fullWidth
          alignRight
          style={{ gap: 16 }}
        >
          <a
            href={
              location.pathname.includes('/en/')
                ? `${process.env.REACT_APP_WEB_URL}/${location.pathname.replace('/en/', '')}`
                : `${process.env.REACT_APP_WEB_URL}${location.pathname}`
            }
            title={'한국어'}
          >
            <Text baseline="primary" size="xs">
              한국어
            </Text>
          </a>
          <a
            href={
              location.pathname.includes('/en/')
                ? `${process.env.REACT_APP_WEB_URL}${location.pathname}`
                : `${process.env.REACT_APP_WEB_URL}/en${location.pathname}`
            }
            title={'English'}
          >
            <Text baseline="primary" size="xs">
              English
            </Text>
          </a>
        </Box>
        <Box baseline="none" className={classes(w100, h100)} spaceBetween fullWidth alignCenterY>
          <Box
            baseline="none"
            className={classes(pl20, mr16)}
            flex
            fullHeight
            alignCenterY
            maxWidth={450}
            fullWidth
          >
            <Logo scrolled={scrolled} />
          </Box>
          <Box
            className={classes(pl16, pr16)}
            baseline="none"
            fullWidth
            flex
            alignCenterY
            alignCenterX
            maxWidth={960}
          >
            <Box baseline="none" maxWidth={400} fullWidth>
              {/* <Search size={'lg'} /> */}
            </Box>
          </Box>
          <Box
            baseline="none"
            fullHeight
            flex
            alignCenterY
            className={pr20}
            maxWidth={450}
            fullWidth
            alignRight
          >
            <Box baseline="none" className={hidesmi} flex>
              {renderLinks()}
            </Box>
            <Box baseline="none" className={classes(flexsm, hide)}>
              {renderLinks(true)}
            </Box>
            {!disableUserMenu && <UserMenu />}
            <Box baseline="none" className={classes(ml8, hide, showsm)}>
              <MobileMenu links={menu} isScrolled={scrolled} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
