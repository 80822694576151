/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import { media, style } from 'typestyle'

/* Constants ==================================================================================== */
import { ZINDEX_8, ZINDEX_6 } from '../__constants/z-index'
import { NAVBAR_WIDTH } from './app-layout.constants'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { createCustomMediaQuery } from '../style-class/style-class.utils'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for navbar
   */
  base: string
  /**
   * Proped class for navbar
   */
  proped: {
    active: string
    offsetted: string
  }

  overlay: string
}

type ClassProps = {
  leftOffset?: number
  topOffset?: number
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { leftOffset, topOffset } = props

  const base = style({
    display: 'flex',
    width: 0,
    overflow: 'hidden',
    position: 'fixed',
    transition: theme.transition.medium,
    transitionProperty: 'top, left, width',
    zIndex: ZINDEX_6,
    ...createCustomMediaQuery({ maxWidth: 992, style: { position: 'fixed' } }),
    ...media({ type: 'print' }, {
      display: 'none'
    })
  })

  const proped = {
    active: style({
      width: NAVBAR_WIDTH,
      overflow: 'visible',
    }),
    offsetted: style({
      left: leftOffset,
      top: topOffset,
      height: `calc(100% - ${topOffset}px)`,
    }),
  }

  const overlay = style({
    width: '100%',
    background: 'rgba(0,0,0,0.5)',
    height: '100%',
    position: 'fixed',
    right: 0,
    zIndex: 5,
    ...createCustomMediaQuery({ minWidth: 992, style: { display: 'none' } }),
  })

  return {
    base,
    proped,
    overlay,
  }
}

/* Export ======================================================================================= */
export const navbarClass = new StyleClass<ClassNames, ClassProps>(getNames)
