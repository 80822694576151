/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { createContext } from 'react'

export const GlobalContext: any = createContext<{ cookies: {} }>({
  cookies: {},
})

export const GlobalContextProvider = (props: any) => {
  const { children, cookies = {} } = props

  return <GlobalContext.Provider value={{ cookies }}>{children}</GlobalContext.Provider>
}
