/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { useEffect } from 'react'
import { FloatDialog } from './float-dialog.component'

export const Float: React.FC<{
  anchorPosition?: any
  close: () => void
  shouldCloseOnClick?: boolean
  dialogPosition?: any
  fullWidth?: any
  offsetY?: number
  open?: boolean
  ref: any
  width?: any
  fixed?: boolean
}> = React.forwardRef(
  (
    {
      anchorPosition,
      children,
      close,
      dialogPosition,
      fullWidth,
      offsetY,
      open,
      shouldCloseOnClick,
      width,
      fixed,
    },
    ref,
  ) => {
    useEffect(() => {
      const onScroll = () => {
        close()
      }
      window.addEventListener('scroll', onScroll)
      window.addEventListener('resize', onScroll)

      return () => {
        window.removeEventListener('scroll', onScroll)
        window.removeEventListener('resize', onScroll)
      }
    }, [])

    if (open && (ref as any).current) {
      const parentRect = (ref as any).current.getBoundingClientRect()

      return (
        <FloatDialog
          anchorPosition={anchorPosition}
          dialogPosition={dialogPosition}
          fullWidth={fullWidth}
          offsetY={offsetY}
          onClick={shouldCloseOnClick && close}
          open={open}
          parentRef={ref}
          width={width}
          parentRect={parentRect}
          fixed={fixed}
        >
          {children}
        </FloatDialog>
      )
    }

    return <></>
  },
)
