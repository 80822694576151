/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import React, { useEffect } from 'react'
import useValue from '../../hooks/use-value'

export const Loader = (props: {
  loaded?: boolean
  skeleton?: any
  component?: any
  timeout?: number
}) => {
  const { loaded, skeleton, component, timeout = 0 } = props
  const [loadedInternal, $loadedInternal] = useValue(loaded)

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        $loadedInternal.set(true)
      }, timeout)
    }
  }, [loaded])

  return <>{loadedInternal ? component : skeleton}</>
}
