/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { FC, useContext, useEffect } from 'react'
import { classes } from 'typestyle'
import Cookies from 'js-cookie'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Constants ==================================================================================== */
import { TOPBAR_HEIGHT, HEADER } from './app-layout.constants'

/* Styles ======================================================================================= */
import { headerClass } from './header.class'

/* Components =================================================================================== */
import { AppContext } from './app-layout.controller'
import { GlobalContext } from '../../contexts/global-context'

/* Types ======================================================================================== */
type HeaderProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  context: React.Context<AppContext>
}

const defaultProps: {} = {}

/* <Header /> ================================================================================= */
const HeaderComponent: React.FC<HeaderProps> = props => {
  const { className, children, style, context } = props
  const appLayoutContext = useContext(context)
  const globalContext: any = useContext(GlobalContext)
  const { components, $components, config, id } = appLayoutContext
  const { topbar } = components
  const [active, $active] = useBoolean(config.header.active)
  const [visible, $visible] = useBoolean(
    typeof window !== 'undefined'
      ? JSON.parse(Cookies.get(`${id ? `${id}-` : ''}${HEADER}`) || 'true') && active
      : JSON.parse(globalContext.cookies[`${id ? `${id}-` : ''}${HEADER}`] || 'true'),
  )
  const [loaded, $loaded] = useBoolean(false)

  const show = () => $visible.set(true)
  const hide = () => $visible.set(false)
  const toggle = () => $visible.toggle()

  useEffect(() => {
    $components.patch(HEADER, {
      active,
      $active,
      toggle,
      show,
      hide,
    })

    const timeout = setTimeout(() => {
      $loaded.set(true)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    $components.patch(HEADER, {
      active,
      $active,
      visible,
      show,
      hide,
      toggle,
    })

    if (visible) {
      Cookies.set(`${id ? `${id}-` : ''}${HEADER}`, 'true')
    } else {
      Cookies.set(`${id ? `${id}-` : ''}${HEADER}`, 'false')
    }
  }, [active, visible])

  const getTopOffset = () => {
    let top = 0

    if (topbar && topbar.active) {
      top += TOPBAR_HEIGHT
    }

    return top
  }

  const { base, proped } = headerClass.setProps({ leftOffset: 0, topOffset: getTopOffset() })

  return (
    <div
      className={classes(
        'aeki-header',
        base,
        active && visible && proped.active,
        proped.offsetted,
        loaded && proped.loaded,
        className,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

HeaderComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Header = HeaderComponent
