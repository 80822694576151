/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import { media, style } from 'typestyle'

/* Constants ==================================================================================== */
import { ZINDEX_5 } from '../__constants/z-index'
import { TOOLBAR_HEIGHT } from './app-layout.constants'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for toolbar
   */
  base: string
  /**
   * Proped class for toolbar
   */
  proped: {
    active: string
    offsetted: string
    loaded: string
  }
}

type ClassProps = {
  leftOffset?: number
  topOffset?: number
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { leftOffset, topOffset } = props

  const base = style({
    alignItems: 'center',
    display: 'flex',
    height: 0,
    overflow: 'hidden',
    position: 'fixed',
    zIndex: ZINDEX_5,
    ...media({ type: 'print' }, {
      display: 'none'
    })
  })

  const proped = {
    active: style({
      height: TOOLBAR_HEIGHT,
      overflow: 'visible',
    }),
    offsetted: style({
      left: leftOffset,
      top: topOffset,
      width: `calc(100% - ${leftOffset}px)`,
    }),
    loaded: style({
      transition: theme.transition.medium,
      transitionProperty: 'top, left, width',
    }),
  }

  return {
    base,
    proped,
  }
}

/* Export ======================================================================================= */
export const toolbarClass = new StyleClass<ClassNames, ClassProps>(getNames)
