/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { ZINDEX_0, ZINDEX_9 } from '../__constants/z-index'
import { important } from 'csx'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for float dialog
   */
  base: string
  /**
   * Proped class for float dialog
   */
  proped: {
    open: string
    fullWidth: string
    show: string
    fixed: string
  }
  /**
   * Base class for content
   */
  contentBase: string

  contentProped: {
    fullWidth: string
  }
}

type ClassProps = {}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const base = style({
    opacity: 0,
    overflow: 'hidden',
    // position: 'absolute',
    transition: theme.transition.fast,
    transitionProperty: 'opacity',
    visibility: 'hidden',
    zIndex: ZINDEX_0,
  })

  const proped = {
    open: style({
      overflow: 'initial',
      visibility: 'visible',
      zIndex: ZINDEX_9,
    }),
    fullWidth: style({
      width: '100%',
    }),
    show: style({
      opacity: important(1),
    }),
    fixed: style({
      // position: 'fixed',
    }),
  }

  const contentBase = style({
    position: 'absolute',
    width: 'fit-content',
  })

  const contentProped = {
    fullWidth: style({
      width: '100% !important',
    }),
  }

  return {
    base,
    proped,
    contentBase,
    contentProped,
  }
}

/* Export ======================================================================================= */
export const floatDialogClass = new StyleClass<ClassNames, ClassProps>(getNames)
