/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import React, { useRef, ReactElement } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { stylesheet } from 'typestyle'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
import { Float } from '../float'

/* <Dropdown /> ================================================================================= */
export const Dropdown: React.FC<{
  dataKey?: string
  button?: any
  children: [ReactElement, any]
  floatProps?: any
  className?: string
  noWrap?: boolean
  fullWidth?: boolean
  onClose?: any
}> = ({ className, children, floatProps, noWrap, fullWidth, onClose }) => {
  const [, $focus] = useBoolean(false)
  const parentRef = useRef(null)
  const [open, $open] = useBoolean(false)

  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!open) {
      $focus.toggle()
      $open.toggle()
    }
  }

  const handleClose = () => {
    $focus.set(false)
    $open.set(false)
    if (onClose) onClose()
  }

  if (noWrap) {
    return (
      <>
        <div className={STYLES.containerButton} onClick={handleClick} ref={parentRef}>
          {React.cloneElement(children[0])}
        </div>
        <Float
          ref={parentRef}
          anchorPosition={'bottom right'}
          dialogPosition={'top right'}
          close={handleClose}
          fullWidth={fullWidth}
          open={open}
          {...floatProps}
        >
          {children[1]}
        </Float>
      </>
    )
  }

  return (
    <div className={className} ref={parentRef}>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <div className={STYLES.containerButton} onClick={handleClick} ref={parentRef}>
          {React.cloneElement(children[0])}
        </div>
        <Float
          ref={parentRef}
          anchorPosition={'bottom right'}
          dialogPosition={'top right'}
          close={handleClose}
          open={open}
          {...floatProps}
        >
          {children[1](handleClose)}
        </Float>
      </OutsideClickHandler>
    </div>
  )
}

const STYLES = stylesheet({
  containerButton: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
})
