/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import React from 'react'
import loadable from '@loadable/component'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

/* Common ======================================================================================= */
import config from '../../../config'

/* Contexts ===================================================================================== */
import NavigationWrapper from '../../contexts/navigation'

/* Components =================================================================================== */
import { Modal } from '../../components/modal'

/* Routes ======================================================================================= */
import pages from '../../../routes'

export const AsyncPage = (props: {
  name: string
  path?: string
  injection?: any
  public?: boolean
  component?: any
  exact?: boolean
}) => {
  const { path, injection, name, component, exact } = props
  const Component = component || loadable(() => import(`./${name}`))
  const seo = path
    ? (config.seo as any)[path || 'default'] || config.seo.default
    : config.seo.default

  if (props.public) {
    return (
      <Route
        path={path}
        exact={exact}
        component={() => {
          return (
            <>
              <Helmet>
                <title>{seo.title}</title>
              </Helmet>
              <Component injection={injection} eject={() => {
                ; (window as any).__aeki__ = null
              }} />
              <Modal.render />
            </>
          )
        }}
      />
    )
  }

  return (
    <Route
      path={path}
      exact={exact}
      component={() => {
        return (
          <NavigationWrapper key={typeof window !== 'undefined' ? window.location.pathname : ''}>
            <Component injection={injection} eject={() => {
              ; (window as any).__aeki__ = null
            }} />
            <Modal.render />
          </NavigationWrapper>
        )
      }}
    />
  )
}

export default (state: any) => {
  return (
    <Switch>
      {/* User Specific */}
      <AsyncPage path="/a/signin" name={'user-signin'} public />
      <AsyncPage path="/a/login-loading" name={'user-signin-loading'} public />
      <AsyncPage path="/a/register" name={'user-register'} public />
      <AsyncPage
        path="/a/register-third-party"
        name={'user-register-third-party'}
        public
      />
      <AsyncPage path="/a/password-forget" name={'user-password-forget'} public />
      <AsyncPage
        path="/a/users/password-reset/:passwordResetLink"
        name={'user-password-reset'}
        public
      />
      <AsyncPage path="/a/confirmation" name={'user-confirmation'} public />
      <AsyncPage
        path="/a/verify-form/:verificationToken"
        name={'user-verify'}
        public
      />


      <AsyncPage path="/a/groups/:groupId/page/:pageId/parent/:parentRowId" name={'page'} />
      <AsyncPage path="/a/groups/:groupId/page/:pageId" name={'page'} />
      <AsyncPage
        path="/a/groups/:groupId/base/:baseId/table/:rowId/edit"
        name={'form'}
      />
      <AsyncPage
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table/:rowId/edit"
        name={'form'}
      />
      <AsyncPage path="/a/groups/:groupId/base/:baseId/table/new" name={'form'} />
      <AsyncPage
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table/new"
        name={'form'}
      />

      <AsyncPage path="/a/groups/:groupId/base/:baseId/table/:rowId" name={'row'} />
      <AsyncPage
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table/:rowId"
        name={'row'}
      />

      <AsyncPage path="/a/groups/:groupId/base/:baseId/table" name={'table'} />
      <AsyncPage
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId/table"
        name={'table'}
      />
      <AsyncPage path="/a/groups/:groupId/base/:baseId" name={'table'} />
      <AsyncPage
        path="/a/groups/:groupId/base/:baseId/parent/:parentRowId"
        name={'table'}
      />

      <AsyncPage path="/a/groups/:groupId/all" name={'group-all'} />
      <AsyncPage path="/a/groups/:groupId/base" name={'app'} />
      <AsyncPage path="/a/groups/:groupId" name={'dashboard'} />
      <AsyncPage path="/a/groups" name={'groups'} />

      <AsyncPage path="/a/user/page/:pageId/parent/:parentRowId" name={'page'} />
      <AsyncPage path="/a/user/page/:pageId" name={'page'} />
      <AsyncPage path="/a/user/base/:baseId/table/:rowId/edit" name={'form'} />
      <AsyncPage path="/a/user/base/:baseId/table/new" name={'form'} />
      <AsyncPage path="/a/user/base/:baseId/table/:rowId" name={'row'} />
      <AsyncPage path="/a/user/base/:baseId/table" name={'table'} />
      <AsyncPage
        path="/a/user/base/:baseId/parent/:parentRowId/table/:rowId/edit"
        name={'form'}
      />
      <AsyncPage
        path="/a/user/base/:baseId/parent/:parentRowId/table/new"
        name={'form'}
      />
      <AsyncPage
        path="/a/user/base/:baseId/parent/:parentRowId/table/:rowId"
        name={'row'}
      />
      <AsyncPage
        path="/a/user/base/:baseId/parent/:parentRowId/table"
        name={'table'}
      />
      <AsyncPage path="/a/user/inbox" name={'app'} />
      <AsyncPage path="/a/user" name={'dashboard'} />

      <AsyncPage path="/page-not-found" name={'page-not-found'} />
      {pages(state).props.children}
      <AsyncPage name={'page-not-found'} />
    </Switch>
  )
}
