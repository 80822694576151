/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import useValue, { UseValue } from '../use-value'

type BooleanCallbacks = {
  /** Sets current value to the opposite */
  readonly toggle: () => void
}

/**
 * useBoolean
 *
 * Returns a stateful boolean value, and functions to interact with it
 *
 * @param initialValue Initial value
 */
const useBoolean: UseValue<boolean, BooleanCallbacks> = (initialValue = false) => {
  const [value, $value] = useValue(initialValue)
  const toggle = () => {
    $value.set((prev: boolean) => {
      return !prev
    })
  }

  return [value, { ...$value, toggle }]
}

export default useBoolean
