/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
export const LAYOUT_XS = 400
export const LAYOUT_SM = 576
export const LAYOUT_MD = 768
export const LAYOUT_LG = 992
export const LAYOUT_XL = 1200
