/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import { useEffect, useState } from 'react'

/* Hooks ---------------------------------------------------------------------------------------- */
import useObject, { UseObjectCallbacks } from '../use-object'
import useValue, { UseValueCallbacks } from '../use-value'

/* Common --------------------------------------------------------------------------------------- */
import { structureObj, flat } from '../../share/obj-equal'
import { getCurrentUser } from '../../services/auth'

/* Types ---------------------------------------------------------------------------------------- */
export type UseFetchState<TYPE> = {
  payload: TYPE
  error: any
  meta: any
  search: any
  searchText?: any
  loaded: boolean
  mounted: boolean
  isLoading?: boolean
}

type UseFetchCallbacks = {
  /** Axios.get */
  get: any
  delete: any
  post: any
  put: any
  patch: any
  reset: any
  $options: UseObjectCallbacks<{}>
}

type UseFetch = <TYPE>(
  initialValue?: TYPE,
  options?: {
    search?: {}
    location?: any
    url?: string
    isLoading?: boolean
    updateGetParams?: boolean
    onLocationChange?: (props: any) => void
    allowDeepMerge?: boolean
  },
) => [UseFetchState<TYPE>, UseFetchCallbacks]

/* Constants ------------------------------------------------------------------------------------ */
const INITIAL_STATE = {
  loading: false,
  loadingInitial: false,
  error: undefined,
  payload: undefined,
  mounted: false,
}

/**
 * create a file called axios.utils.ts
 * and move this function to there
 *
 * http://localhost:3000/admin/schema?limit=10&page=1
 * to
 * {
 *  limit: 10,
 *   page: 1
 * }
 */
export const parseSearch = (location: any, defaultSearch: any) => {
  const searchQuery: string = location.search.substring(1, location.search.length)
  const parsedSearchQuery = searchQuery.split('&')
  const parsedSearchQueryObject: { [key: string]: any } = {}

  parsedSearchQuery.map(item => {
    const itemParsed = item.split('=')
    const key = decodeURI(itemParsed[0])
    const value = itemParsed[1]

    if (key) {
      parsedSearchQueryObject[key] = decodeURIComponent(value)

      if (!isNaN(parsedSearchQueryObject[key])) {
        parsedSearchQueryObject[key] = parseInt(parsedSearchQueryObject[key])
      }
    }
  })

  const newSearch = {
    ...defaultSearch,
    ...structureObj(parsedSearchQueryObject),
  }

  return newSearch
}

export const parseUrlParms = (url: string, params: any) => {
  const flattenParam = flat(params)

  let encodedParam = ''

  for (const key in flattenParam) {
    if (typeof flattenParam[key] !== 'object') {
      if (encodedParam !== '') {
        encodedParam += '&'
      }
      encodedParam += key + '=' + encodeURIComponent(flattenParam[key])
    }
  }

  return {
    url,
    encodedParam,
  }
}

const deepMerge = (prev: any, val: any) => {
  const isObj = (elem: object) => elem && typeof elem === 'object'

  Object.keys(val).forEach(key => {
    // Check old/new obj if typeof is object then do deep merge
    // Otherwise use new obj to replace current
    if (prev && isObj(prev[key]) && isObj(val[key])) {
      prev[key] = deepMerge(prev[key], val[key])
    } else {
      prev = {
        ...prev,
        [key]: val[key],
      }
    }
  })

  return prev
}

const parseCookie = (str: string) => {
  try {
    return str
      .split(';')
      .map(v => v.split('='))
      .reduce((acc: any, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
        return acc
      }, {})
  } catch (e) {
    return {}
  }
}

/**
 * useFetch
 *
 * @param url initial and only url for endpoint
 */
const useFetch: UseFetch = (initialValue, options = {}) => {
  const [state, $state] = useObject(INITIAL_STATE)
  const [defaultOptions = {}, $options] = useObject(options)
  const [payload, $payload] = useObject(initialValue || {})
  const [error, $error] = useObject({})
  const [meta, $meta] = useObject<any>({
    isLoading: options.isLoading,
  })
  const [_search, $search] = useObject(options.search)
  const [_searchText, $searchText] = useObject()
  const [_get, $_get] = useState(() => { })
  const [_delete, $_delete] = useState(() => { })
  const [_post, $_post] = useState(() => { })
  const [_put, $_put] = useState(() => { })
  const [_patch, $_patch] = useState(() => { })
  const [loaded, $loaded] = useValue(undefined)
  const [mounted, $mounted] = useValue(undefined)
  let delayedLoading: any

  useEffect(() => {
    if (options.location) {
      if (options.location.search) {
        $search.set(parseSearch(options.location, _search))
      }
    }
  }, [])

  useEffect(() => {
    if (
      typeof _get !== undefined &&
      typeof _delete !== undefined &&
      loaded === undefined &&
      _post !== undefined &&
      _patch !== undefined
    ) {
      $loaded.set(true)
      $mounted.set(true)
    }
  }, [_get, _delete, _post, _patch, loaded])

  useEffect(() => {
    const callback = (method?: string) => {
      return async (props: {
        url?: string
        search?: any
        location?: boolean
        onLocationChange?: any
        updateGetParams?: boolean
        deleteSearch?: string
        deleteFalse?: boolean
        copySearchProp?: boolean
        resetSearch?: boolean
        body?: any
      }) => {
        const {
          url,
          body,
          search,
          location,
          onLocationChange,
          allowDeepMerge = true,
          deleteSearch,
          deleteFalse,
          copySearchProp,
          resetSearch,
          updateGetParams = defaultOptions.updateGetParams === false
            ? defaultOptions.updateGetParams
            : true,
        } = { ...defaultOptions, ...props }
        let parsedSearch: any = resetSearch ? {} : { ...(copySearchProp ? search : _search) }
        $loaded.set(false)

        if (!copySearchProp) {
          if (allowDeepMerge) {
            parsedSearch = deepMerge(parsedSearch, search || {})
          } else {
            parsedSearch = { ...parsedSearch, ...search }
          }

          if (location) {
            if (updateGetParams) {
              const parsedSearchTemp = parseSearch(location, {})

              if (allowDeepMerge) {
                deepMerge(parsedSearchTemp, parsedSearch)
              } else {
                parsedSearch = { ...parsedSearchTemp, ...parsedSearch }
              }
            } else {
              parsedSearch = parseSearch(location, parsedSearch)
            }
          }

          const flattenParsedSearch = flat(parsedSearch)

          for (const key in flattenParsedSearch) {
            if (!flattenParsedSearch[key]) {
              delete flattenParsedSearch[key]
            }
          }

          if (deleteSearch) {
            delete flattenParsedSearch[deleteSearch]
          }

          for (const key in flattenParsedSearch) {
            if (flattenParsedSearch[key] === null) {
              delete flattenParsedSearch[key]
            }

            if (deleteFalse) {
              if (flattenParsedSearch[key] === false) {
                delete flattenParsedSearch[key]
              }
            }
          }

          parsedSearch = structureObj(flattenParsedSearch)
        }

        $search.set(parsedSearch)

        const parsedUrlStructure = parseUrlParms(url || '', parsedSearch)

        const parsedUrl = `${parsedUrlStructure.url}${parsedUrlStructure.encodedParam ? `?${parsedUrlStructure.encodedParam}` : ''
          }`

        $searchText.set(parsedUrlStructure.encodedParam)

        if (updateGetParams && onLocationChange) {
          onLocationChange(parsedUrlStructure)
        }

        clearTimeout(delayedLoading)
        $meta.update({
          isLoading: true,
          isLoadingDelayed: true,
          timestamp: new Date(),
        })

        const headers = new Headers(getCurrentUser().token ? {
          authorization: getCurrentUser().token,
          'Content-Type': 'application/json',
        } : {
          'Content-Type': 'application/json',
        })

        const response = await fetch(
          parsedUrl,
          method ? { method, headers, body: JSON.stringify(body || { from: 'web' }) } : { headers },
        )

        if (response.ok) {
          const responseJson = await response.json()

          $meta.update({
            contentType: response.headers.get('Content-Type'),
            status: response.status,
            statusText: response.statusText,
            type: response.type,
            url: response.url,
          })

          $payload.set(responseJson)
          $meta.update({
            isLoading: false,
          })

          delayedLoading = setTimeout(() => {
            $meta.update({
              isLoadingDelayed: false,
            })
          }, 200)

          return responseJson
        } else {
          $error.set(response)
          $meta.update({
            isLoading: false,
          })

          throw response
        }
      }
    }

    $_get(() => {
      return callback()
    })

    $_delete(() => {
      return callback('DELETE')
    })

    $_post(() => {
      return callback('POST')
    })

    $_put(() => {
      return callback('PUT')
    })

    $_patch(() => {
      return callback('PATCH')
    })
  }, [_search, defaultOptions])

  return [
    {
      payload: payload as any,
      meta,
      error,
      search: _search,
      searchText: _searchText,
      mounted: mounted,
      loaded: loaded && typeof _get === 'function',
    },
    {
      get: _get,
      delete: _delete,
      post: _post,
      put: _put,
      patch: _patch,
      reset: () => {
        $payload.reset()
      },
      $options,
    },
  ]
}

export default useFetch
