/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { FC } from 'react'
import { classes } from 'typestyle'

import { ComponentBaselineType, ComponentProps } from '../__core/component.types'

import { textClass } from './text.class'

export type TextComponentProps = {
  alt?: boolean
  baseline?: ComponentBaselineType
  children?: any
  className?: string
  as?: any
  semiBold?: boolean
  bold?: boolean
  italic?: boolean
  link?: boolean
  sublink?: boolean
  uppercase?: boolean
  preWrap?: boolean
  preLine?: boolean
  disableFlex?: boolean
  readable?: boolean
  crank?: boolean
  sub?: string
  secondary?: boolean
  disableLineHeight?: boolean
} & ComponentProps

const defaultProps: {
  baseline?: ComponentBaselineType
  as?: JSX.Element
} = {
  baseline: 'none',
  as: <span />,
}

export const createTextComponent = (textClass: any) => {
  const TextComponent: FC<TextComponentProps> = props => {
    const {
      alt,
      baseline,
      className,
      children,
      as,
      bold,
      italic,
      link,
      semiBold,
      uppercase,
      size,
      style,
      preWrap,
      preLine,
      sublink,
      readable,
      crank,
      sub,
      secondary,
      disableLineHeight,
    } = props

    const {
      base,
      baseThemed,
      baseSemiBold,
      baseBold,
      baseItalic,
      baseLink,
      baseUppercase,
      baseSized,
      basePreWrap,
      basePreLine,
      baseSublink,
      baseReadable,
      baseSecondary,
      baseNoLineHeight,
    } = textClass.setProps({ alt, baseline, crank, sub })

    return React.cloneElement(as, {
      className: classes(
        base,
        baseThemed,
        baseSized[size || 'md'],
        semiBold && baseSemiBold,
        bold && baseBold,
        italic && baseItalic,
        link && baseLink,
        uppercase && baseUppercase,
        preWrap && basePreWrap,
        preLine && basePreLine,
        sublink && baseSublink,
        readable && baseReadable,
        secondary && baseSecondary,
        disableLineHeight && baseNoLineHeight,
        className,
      ),
      style,
      children,
      ...as.props,
      onClick: props.onClick,
    })
  }

  TextComponent.defaultProps = defaultProps

  return TextComponent
}

export const Text = createTextComponent(textClass)
