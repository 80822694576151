/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { media, style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Utils ======================================================================================== */
import { getBoxSize, getButtonSize } from './button.utils'

/* Component===================================================================================== */
import {
  ComponentLeftRightType,
  ComponentSizeType,
  ComponentBaselineType,
} from '../__core/component.types'
import { important } from 'csx'
import { buttonSizes } from './button.constants'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for the most parent <button />
   */
  base: string

  proped: {
    float: string
  }
  /**
   * Themed extension class for <button />
   */
  baseThemed: string
  /**
   * Animated class for <button />
   */
  baseAnimated: string
  /**
   * Base class for child <span /> that holds icon
   */
  iconBase: string

  arrowBase: string
  baseBold: string
  baseSemiBold: string
}

type ClassProps = {
  alt?: boolean
  baseline?: ComponentBaselineType
  float?: boolean
  icon: any
  iconPosition?: ComponentLeftRightType
  label?: string
  size?: ComponentSizeType
  square?: boolean
  center?: boolean
  arrow?: 'below' | 'above' | 'left' | 'right'
  border?: boolean
  borderContrast?: 'light' | 'medium' | 'dark'
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const {
    label,
    baseline,
    alt,
    size,
    iconPosition,
    icon,
    square,
    center = true,
    arrow,
    border,
    borderContrast = 'medium',
  } = props
  const { borderRadius, height, minWidth, $nest } = getButtonSize(size, iconPosition, !!icon)

  const iconBase = style({
    // zIndex: 2,
    $nest: {
      '.MuiSvgIcon-root': {
        color: label ? 'black' : theme.getBaseline(baseline, alt).contrast.dark,
      },
    },
  })

  let padding

  if (icon) {
    padding = buttonSizes[size || 'md'].paddingIconRight
  } else {
    padding = buttonSizes[size || 'md'].padding
  }

  let arrowBase = ''

  switch (arrow) {
    case 'below': {
      arrowBase = style({
        bottom: -2,
        zIndex: -1,
        left: '50%',
        transform: 'translateX(-50%) rotate(45deg)',
        background: 'inherit',
      })
    }
  }

  const base = style({
    alignItems: 'center',
    display: 'flex',
    flex: 'none',
    position: 'relative',
    userSelect: 'none',
    textAlign: center ? 'center' : 'initial',
    background: 'none',
    padding,
    fontSize: buttonSizes[size || 'md'].fontSize,
    '-webkit-appearance': important('initial'),
    $nest: {
      '&:hover': {
        color: 'initial',
      },
    },
    ...media(
      { type: 'print' },
      {
        display: 'none',
      },
    ),
  })

  const baseThemed = style({
    height,
    minWidth,
    borderRadius: square ? 4 : borderRadius,
    cursor: baseline === 'disabled' ? 'default' : 'pointer',
    background: theme.getBaseline(baseline, alt).color.medium,
    transition: theme.transition.medium,
    transitionProperty: 'color, background, transform',
    border: border ? `1px solid ${theme.boxes.border[borderContrast]}` : 'none',
    $nest: {
      [`& .${arrow} `]: {
        background: 'inherit',
      },
      '& .stroke': {
        stroke: theme.getBaseline(baseline, alt).contrast.dark,
      },
      '&:focus': {
        background: theme.getBaseline(baseline, alt).color.dark,
      },
      '&:hover': {
        background: theme.getBaseline(baseline, alt).color.dark,
        $nest: {
          [`& .${arrow} `]: {
            background: theme.getBaseline(baseline, alt).color.dark,
          },
          '&:after': {
            color: theme.getBaseline(baseline, alt).contrast.medium,
          },
        },
      },
      '&:active:hover': {
        transform: 'scale(0.97)',
      },
      '&::after': {
        color: theme.getBaseline(baseline, alt).contrast.dark,
        content: `"${label || ''}"`,
        padding: !label ? 0 : undefined,
        whiteSpace: 'nowrap',
        zIndex: 1,
        width: '100%',
        letterSpacing: 0.4,
        lineHeight: 0,
        textAlign: center ? 'center' : 'left',
        marginTop: size === 'xs' ? -2 : 0,
        ...(label && $nest['&:::after']),
      },
      span: {
        color: theme.getBaseline(baseline, alt).contrast.medium,
      },
      ...$nest,
      [`& .${iconBase} `]: {
        // filter: 'invert(1) sepia(0) saturate(10) hue-rotate(0deg)',
        color: theme.getBaseline(baseline, alt).contrast.dark,
        alignItems: 'center',
        borderRadius: '50%',
        display: 'flex',
        height: getBoxSize(size),
        justifyContent: 'center',
        // position: center ? 'absolute' : '',
        position: 'absolute',
        width: size === 'xs' ? getBoxSize(size) - 2 : getBoxSize(size),
        ...(iconPosition === 'right'
          ? {
              right: 0,
            }
          : {
              left: 0,
            }),
        $nest: {
          '& .stroke': {
            stroke: theme.getBaseline(baseline, alt).contrast.dark,
          },
        },
      },
    },
  })

  const baseAnimated = style({
    $nest: {
      '&:active:hover': {
        // transform: 'translateY(-1px)',
      },
    },
  })

  const proped = {
    float: style({
      boxShadow: `1px 1px 10px 1px ${theme.boxes.shadow.medium} `,
    }),
  }

  const baseBold = style({
    fontWeight: 700,
  })

  const baseSemiBold = style({
    fontWeight: 500,
  })

  return {
    base,
    proped,
    baseAnimated,
    baseThemed,
    iconBase,
    arrowBase,
    baseBold,
    baseSemiBold,
  }
}

export const buttonClass = new StyleClass<ClassNames, ClassProps>(getNames)
