/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { useEffect } from 'react'
import { classes, stylesheet } from 'typestyle'
import { CSSTransition } from 'react-transition-group'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { Button } from '../button'
import { Text } from '../text/text.component'
import { Box } from '../box/box.component'

/* Styles ======================================================================================= */
import { mt16 } from '../../styles/styleset/margin/mt16'
import { mr8 } from '../../styles/styleset/margin/mr8'
import { p12 } from '../../styles/styleset/padding/p12'
import { createMediaQuery } from '../style-class/style-class.utils'
import { pb8 } from '../../styles/styleset/padding/pb8'

/* Types ======================================================================================== */
type Modal = {
  render?: any
  confirmRender?: any
  confirm?: () => void
  cancelRender?: any
  cancel?: () => void
  label?: string
  disableAction?: boolean
  status?: string
  props?: any
  type?: string
  componentProps?: any
  title?: string | any
  containerClassName?: string
  position?: any
}

/* ModalStack =================================================================================== */
class ModalStack {
  count = 0
  self: any = null
  storage: Modal[] = []
  components: any = {}

  setComponents = (components: any) => {
    this.components = components
  }

  add = (name: string, component: any) => {
    this.components[name] = component
  }

  push = (dialog: Modal) => {
    this.storage[this.count + 1] = dialog
    this.count++

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  pop = () => {
    delete this.storage[this.count]

    this.count--

    if (this.count < 0) {
      this.count = 0
    }

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  update = (props?: any) => {
    this.storage[this.count].props = {
      ...this.storage[this.count].props,
      ...props,
    }

    this.self.set(this)
  }

  size = () => this.count

  render = () => {
    const [self, $self] = useValue(this)
    // const [currentModal, $currentModal] = useValue(this)
    this.self = $self

    useEffect(() => {
      if (self.count > 0) {
        // document.documentElement.style.touchAction = 'none'
        // document.body.style.touchAction = 'none'
        // document.body.style.overflow = 'hidden'
      } else {
        // document.documentElement.style.touchAction = ''
        // document.body.style.touchAction = ''
        // document.body.style.overflow = ''
      }
    }, [self])

    const handleContainerClick = () => {
      self.pop()
    }

    const handleContentClick = (event: any) => {
      event.stopPropagation()
    }

    return (
      <CSSTransition in={self.count > 0} timeout={300} classNames="popin">
        <>
          {self.storage.map((dialog: Modal, key: number) => {
            const { componentProps, type = 'Prompt' } = dialog

            switch (type) {
              case 'Prompt': {
                const handdleConfirm = () => {
                  if (dialog.componentProps.confirm) {
                    dialog.componentProps.confirm()
                  } else {
                    this.pop()
                  }
                }
                return (
                  <ModalContainer onClick={handleContainerClick} key={key}>
                    <ModalContentContainer onClick={handleContentClick}>
                      <Box className={pb8}>
                        <Text semiBold>{dialog.componentProps.label}</Text>
                      </Box>
                      <Box flex alignRight className={mt16}>
                        <Button
                          className={mr8}
                          label="Cancel"
                          square
                          size="sm"
                          onClick={handleContainerClick}
                        />
                        <Button
                          label={componentProps.confirmLabel || 'Confirm'}
                          baseline="primary"
                          size="sm"
                          onClick={handdleConfirm}
                          square
                        />
                      </Box>
                    </ModalContentContainer>
                  </ModalContainer>
                )
              }

              case 'Info': {
                return (
                  <ModalContainer onClick={handleContainerClick} key={key}>
                    <ModalContentContainer onClick={handleContentClick}>
                      <Box className={pb8}>
                        <Text semiBold>{dialog.componentProps.label}</Text>
                      </Box>
                      <Box flex alignRight className={mt16}>
                        <Button
                          label="Confirm"
                          baseline="primary"
                          size="sm"
                          onClick={handleContainerClick}
                          square
                        />
                      </Box>
                    </ModalContentContainer>
                  </ModalContainer>
                )
              }

              default: {
                const Component = this.components[type as any]

                return (
                  <ModalContainer
                    key={key}
                    position={dialog.position}
                    onClick={handleContainerClick}
                  >
                    <Box
                      className={classes(dialog.containerClassName || '', STYLES.container)}
                      rounded
                      relative
                    >
                      {dialog.title && (
                        <Box
                          className={classes(p12)}
                          // sticky
                          style={{
                            // top: 0,
                            borderRadius: '4px 4px 0 0',
                            borderBottom: '1px solid rgb(234,234,234)',
                          }}
                          onlyContrast
                        >
                          <Text size="xl">{dialog.title || 'Modal'}</Text>
                        </Box>
                      )}
                      <Component
                        key={key}
                        {...componentProps}
                        handleContentClick={handleContentClick}
                        pop={self.pop}
                      />
                    </Box>
                  </ModalContainer>
                )
              }
            }
          })}
        </>
      </CSSTransition>
    )
  }
}

/* Styled Components ============================================================================ */
const ModalContentContainer: any = (props: any) => (
  <div className={STYLES.containerModalContent} {...props}>
    {props.children}
  </div>
)

export const ModalContainer = (props: any) => {
  let positionObj = {}

  switch (props.position) {
    case 'top left': {
      positionObj = {
        alignLeft: true,
        alignTop: true,
      }
      break
    }
    // case 'top right': {
    //   // positionObj = {
    //   //   alignRight: true,
    //   //   alignTop: true,
    //   //   // alignBottom: true
    //   // }
    //   break
    // }
    default:
      positionObj = {
        alignCenterY: true,
        alignCenterX: true,
      }
      break
  }

  return (
    <Box className={STYLES.containerModal} {...props} {...positionObj}>
      {props.children}
    </Box>
  )
}

export const ModalActionContainer = (props: any) => (
  <div className={STYLES.containerModalActions} {...props}>
    {props.children}
  </div>
)

const STYLES = stylesheet({
  containerModal: {
    background: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    position: 'fixed',
    padding: 8,
    top: 0,
    transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
    width: '100%',
    zIndex: 11,
    $nest: {
      '&.popin-enter': {
        opacity: 0,
        transform: 'translateY(-8px)',
        transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
      },

      '&.popin-enter-active': {
        opacity: 1,
        transform: 'translateY(0px)',
        transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
      },

      '&.popin-exit': {
        opacity: 1,
        transform: 'translateY(0px)',
        transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
      },

      '&.popin-exit-active': {
        opacity: 0,
        transform: 'translateY(-8px)',
        transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
      },
    },
  },
  containerModalContent: {
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.1)',
    // minWidth: 296,
    padding: 16,
    background: 'white',
    borderRadius: 8,
  },
  containerModalActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16,
    $nest: {
      // [`.${ButtonCore}:last-child`]: {
      //   marginLeft: 8,
      // },
    },
  },
  container: {
    minWidth: 292,
    ...createMediaQuery['md']({
      width: '100%',
      maxWidth: '100%',
      // minWidth: '292px !important',
      minWidth: 'initial',
    }),
  },
})

export const Modal = new ModalStack()
