/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { FC, useEffect } from 'react'

/* Hooks ======================================================================================== */
import useObject from '../../hooks/use-object'

/* Common ======================================================================================= */
import { merge } from '../../share/obj-equal'
import AppLayoutClass1, { ComponentKeys } from './app-layout.controller'

/* Constants ==================================================================================== */
import {
  NAVBAR,
  HEADER,
  TOOLBAR,
  TOPBAR,
  TOOLBAR_HEIGHT,
  HEADER_HEIGHT,
  TOPBAR_HEIGHT,
} from './app-layout.constants'

/* Components =================================================================================== */
import { Header } from './header.component'
import { Navbar } from './navbar.component'
// import { Sidebar } from './sidebar.component'
import { Toolbar } from './toolbar.component'
// import { Topbar } from './topbar'
import { Content } from './content.component'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { Box } from '../box/box.component'
import { ComponentBaselineType } from '../__core/component.types'
import _config from '../../../config'
// import config from '../../../config'
import { Topbar } from './topbar'

/* Types ======================================================================================== */
type AppLayoutRenderProps = {
  config?: any
  components?: any
  content?: any
  topbar?: any
  topbarProps?: any
  header?: any
  headerProps?: any
  toolbarProps?: any
  toolbar?: any
  navbar?: any
  navbarProps?: any
  sidebar?: any
  sidebarProps?: any
  topBar?: any
  head?: any
  baseline?: ComponentBaselineType
}

type test = {
  active: boolean
  $active: any
  hide: any
  visible?: boolean
}
type AppLayoutComponents = {
  topbar: test
  header: test
  menubar: test
  toolbar: test
  navbar: test
}

/* AppLayoutClass =============================================================================== */
// I wanted a factory context. Using context don't have significant meaning within app layout components but is not when other component uses it
export class AppLayoutClass extends AppLayoutClass1 {
  constructor(props?: { id?: string }) {
    super(props)
  }

  renderComponent = (key: ComponentKeys, props: any) => {
    const Component = this.components[key]
    if (this.components[key]) {
      return <Component {...{ ...this.props[key], ...props, ...this }} layoutId={this.id} />
    }
  }

  render: FC<AppLayoutRenderProps> = ({
    config,
    content,
    topbar,
    topbarProps,
    header,
    headerProps,
    navbar,
    navbarProps,
    sidebar,
    sidebarProps,
    toolbar,
    toolbarProps,
    head,
    baseline = 'background',
  }) => {
    // Merge default config with config prop
    config = merge({ ...this.config }, config)

    useEffect(() => {
      if (this.activateBodyStyle) {
        this.activateBodyStyle()
      }
    }, [])
    const history = useHistory()
    const [components, $components] = useObject<AppLayoutComponents>({
      ...config,
    } as any)
    const initialValues = this.getInitialValues(config)
    const renderHead = (
      params: any = {
        title: '',
        description: '',
        mainImage: '',
        nextPage: '',
        prevPage: '',
        url: '',
        canonical: '',
        scripts: [],
      },
    ) => {
      let title, description, mainImage, url, canonical

      if (!params.url) {
        url = `${process.env.REACT_APP_WEB_URL}${history.location.pathname}`
      } else {
        url = params.url
      }

      if (!params.canonical) {
        canonical = `${process.env.REACT_APP_WEB_URL}${history.location.pathname}`
      } else {
        canonical = params.canonical
      }

      if (!params.title || params.title === '-') {
        title = process.env.REACT_APP_PROJECT_NAME
      } else {
        title = params.title
      }

      if (!params.description || params.description === '-') {
        description = process.env.REACT_APP_PROJECT_DESCRIPTION
      } else {
        description = params.description
      }

      if (!params.mainImage || params.mainImage === '-') {
        mainImage = process.env.REACT_APP_PROJECT_MAIN_IMAGE || ''
      } else {
        mainImage = params.mainImage || ''
      }

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={canonical} />
          {params.nextPage && <link rel="next" href={params.nextPage} />}
          {params.prevPage && <link rel="prev" href={params.prevPage} />}
          <link rel="icon" href={process.env.REACT_APP_PROJECT_FAVICON} />
          {process.env.REACT_APP_GOOGLE_STORAGE_URL && (
            <link rel="preconnect" href="https://storage.googleapis.com/" />
          )}
          <link rel="preconnect" href={process.env.REACT_APP_API_URL} />
          <link rel="dns-prefetch" href={process.env.REACT_APP_API_URL} />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons+Two+Tone"
          />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          {(config.head || []).map((item: any, key: any) => {
            return React.createElement(item.type, { ...item.props, key })
          })}
          <meta itemProp="image" content={process.env.REACT_APP_PROJECT_MAIN_IMAGE} />
          <meta name="robots" content="index, follow" />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta
            name="bingbot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content={process.env.REACT_APP_PROJECT_NAME} />
          <meta property="og:image" content={mainImage.replace('https', 'http') || ''} />
          <meta property="og:image:secure_url" content={mainImage.replace('https', 'http') || ''} />
          <meta property="og:image:width" content="360" />
          <meta property="og:image:height" content="240" />

          {(params.scripts || []).map((script: any, key: any) => {
            return <script key={key} {...script} />
          })}
          {(params.preload || []).map((href: any, key: any) => {
            return <link rel="preload" as="image" href={href} key={key} />
          })}
        </Helmet>
      )
    }

    const Provider = this.context.Provider

    if (this.context) {
      return (
        <Provider
          value={{
            config,
            components,
            $components,
            initialValues,
            id: this.id,
            getOffsetTop: () => {
              let offsetTop = 0

              if (components.toolbar.visible) {
                offsetTop += TOOLBAR_HEIGHT
              }

              if (components.header.visible) {
                offsetTop += HEADER_HEIGHT
              }

              if (config.topbar.active) {
                offsetTop += TOPBAR_HEIGHT
              }

              return offsetTop
            },
          }}
        >
          <Box baseline={baseline} alignRight row>
            {renderHead(head)}
            <Header context={this.context}>
              {header || this.renderComponent(HEADER, headerProps)}
            </Header>
            {config.navbar.active && (
              <Navbar context={this.context}>
                {navbar || this.renderComponent(NAVBAR, this.props[NAVBAR] || navbarProps)}
              </Navbar>
            )}
            {config.toolbar.active && (
              <Toolbar context={this.context}>
                {toolbar || this.renderComponent(TOOLBAR, toolbarProps)}
              </Toolbar>
            )}
            <Content context={this.context}>{content}</Content>
          </Box>
          <Topbar context={this.context}>
            {topbar || this.renderComponent(TOPBAR, topbarProps)}
          </Topbar>
        </Provider>
      )
    }

    return <div></div>
  }
}
