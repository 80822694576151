/** Caution - This file is managed by aeki */
import React from 'react'
import loadable from '@loadable/component'
import { Route, useHistory } from 'react-router-dom'
import { Modal } from '../__aeki__/components/modal'

export const AsyncPage = (props: {
  name: string
  path?: string
  injection?: any
  public?: boolean
  component?: any
  exact?: boolean
  state?: any
}) => {
  const { path, injection, name, component, exact, state = {} } = props
  const history = useHistory()
  const Component = component || loadable(() => import(`./${name}`))

  function inject() {
    if (history.location.search.includes('auth=1')) {
      return {}
    }

    if (typeof globalThis.window !== undefined) {
      if ((globalThis.window as any) && (globalThis.window as any).__aeki__) {
        const injection = { ...(globalThis.window as any).__aeki__ }

        console.log(injection)
        return injection || state
      }
    }

    return state
  }

  return (
    <Route
      path={path}
      exact={exact}
      component={() => {
        return (
          <>
            <Component
              injection={injection}
              inject={inject}
              eject={() => {
                ; (window as any).__aeki__ = null
              }}
            />
            <Modal.render />
          </>
        )
      }}
    />
  )
}

export default (state: any) => {
  return (
    <>
      <AsyncPage path={'/a/email-signature'} name={'email-signature'} state={state} />
      <AsyncPage path={'/a/email-signature-en'} name={'email-signature-en'} state={state} />

      <AsyncPage path={'/:loc/a/contact-us'} name={'__contact-us__'} state={state} />
      <AsyncPage path={'/:loc/a/clients'} name={'clients'} state={state} />
      <AsyncPage path={'/:loc/a/company'} name={'company'} state={state} />
      <AsyncPage path={'/:loc/a/services'} name={'solutions'} state={state} />
      <AsyncPage path={'/:loc/a/agreement'} name={'agreement'} state={state} />
      <AsyncPage path={'/:loc/a/email-signature'} name={'email-signature'} state={state} />
      <AsyncPage path={'/:loc/a/email-signature-en'} name={'email-signature-en'} state={state} />
      <AsyncPage path={'/:loc/'} name={'__home__'} state={state} exact />

      <AsyncPage path={'/a/contact-us'} name={'__contact-us__'} state={state} />
      <AsyncPage path={'/a/clients'} name={'clients'} state={state} />
      <AsyncPage path={'/a/company'} name={'company'} state={state} />
      <AsyncPage path={'/a/services'} name={'solutions'} state={state} />
      <AsyncPage path={'/a/agreement'} name={'agreement'} state={state} />
      <AsyncPage path={'/a/chrome-privacy-policy'} name={'chrome-privacy-policy'} state={state} />
      <AsyncPage path={'/a/email-signature'} name={'email-signature'} state={state} />
      <AsyncPage path={'/a/email-signature-en'} name={'email-signature-en'} state={state} />
      <AsyncPage path={'/'} name={'__home__'} state={state} exact />
    </>
  )
}
