/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { FC, useContext, useEffect } from 'react'
import { classes } from 'typestyle'
import Cookies from 'js-cookie'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Constants ==================================================================================== */
import { TOPBAR_HEIGHT, NAVBAR, HEADER_HEIGHT } from './app-layout.constants'

/* Styles ======================================================================================= */
import { navbarClass } from './navbar.class'

/* Components =================================================================================== */
import { AppContext } from './app-layout.controller'
import { GlobalContext } from '../../contexts/global-context'

/* Types ======================================================================================== */
type NavbarProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  layoutId?: string
  context: React.Context<AppContext>
}

const defaultProps: {} = {}

/* <Navbar /> ================================================================================= */
const NavbarComponent: React.FC<NavbarProps> = props => {
  const { className, children, style, context, layoutId } = props
  const appLayoutContext = useContext(context)
  const globalContext: any = useContext(GlobalContext)
  const { config, components, $components, id } = appLayoutContext
  const { header, topbar } = components
  const [active, $active] = useBoolean(config.navbar.active)
  const [visible, $visible] = useBoolean(
    typeof window !== 'undefined'
      ? JSON.parse(Cookies.get(`${id ? `${id}-` : ''}${NAVBAR}`) || 'false') && active
      : JSON.parse(globalContext.cookies[`${id ? `${id}-` : ''}${NAVBAR}`] || 'false'),
  )

  const show = () => $visible.set(true)
  const hide = () => $visible.set(false)
  const toggle = () => $visible.toggle()

  useEffect(() => {
    $components.patch(NAVBAR, {
      active,
      $active,
      visible,
      show,
      hide,
      toggle,
    })
  }, [])

  useEffect(() => {
    $components.patch(NAVBAR, {
      active,
      $active,
      visible,
      show,
      hide,
      toggle,
    })

    if (visible) {
      Cookies.set(`${id ? `${id}-` : ''}${NAVBAR}`, 'true')
    } else {
      Cookies.set(`${id ? `${id}-` : ''}${NAVBAR}`, 'false')
    }
  }, [active, visible])

  const getTopOffset = () => {
    let top = 0

    if (topbar && topbar.active) {
      top += TOPBAR_HEIGHT
    }

    if (header && header.active && header.visible) {
      top += HEADER_HEIGHT
    }

    return top
  }

  const { base, proped, overlay } = navbarClass.setProps({
    leftOffset: 0,
    topOffset: getTopOffset(),
  })

  return (
    <>
      <div
        className={classes(
          'aeki-navbar',
          base,
          active && visible && proped.active,
          proped.offsetted,
          className,
        )}
        style={style}
      >
        {children}
      </div>
      {visible && (
        <div
          className={overlay}
          onClick={() => {
            hide()
          }}
        ></div>
      )}
    </>
  )
}

NavbarComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Navbar = NavbarComponent
