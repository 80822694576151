/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { getCurrentUser } from '../../services/auth'

export const A = (props: any) => {
  const { currentUser } = getCurrentUser()

  let to = props.to

  return <Link {...props} to={to} />
}
