/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import Cookies from 'js-cookie'

const TTL = 42

export const setPreviousRoute = () => {
  Cookies.set(
    'aeki-previous-route',
    `${window.location.pathname}${window.location.search ? window.location.search : ''}`,
  )
}

export const getPreviousRoute: () => string = () => {
  const { currentUser } = getCurrentUser()
  // const url = localStorage.getItem('aeki-previous-route') || '/'
  const url = Cookies.get('aeki-previous-route') || '/'
  const prev = `${url}`

  clearPreviousRoute()

  return prev
}

export const clearPreviousRoute = () => {
  // return localStorage.removeItem('aeki-previous-route')
  return Cookies.remove('aeki-previous-route')
}

export const setCurrentUser = (payload: any) => {
  // localStorage.setItem('aeki-current-user', JSON.stringify(payload))
  Cookies.set('aeki-current-user', JSON.stringify(payload), { expires: TTL })
}

export const getCurrentUser = () => {
  try {
    // const toReturn = JSON.parse(`${localStorage.getItem('aeki-current-user')}`)
    const toReturn = JSON.parse(Cookies.get('aeki-current-user') as string)
    let isAdmin = false

    if (
      toReturn &&
      toReturn.currentUser &&
      toReturn.currentUser.groups.some((group: any) => group && group.id === 'admin')
    ) {
      isAdmin = true
    }

    return toReturn === null ? { notLoaded: true } : { ...toReturn, isAdmin }
  } catch (e) {
    return {}
  }
}

export const clearCurrentUser = () => {
  return Cookies.remove('aeki-current-user')
}

export const updateCurrentUser = async () => {
  const currentUser = getCurrentUser()
  const headers = new Headers(
    currentUser.token
      ? {
        authorization: currentUser.token,
        'Content-Type': 'application/json',
      }
      : {
        'Content-Type': 'application/json',
      },
  )

  const response = await fetch(`${process.env.REACT_APP_API_URL}/a/current-user`, { headers })
  const responseJson = await response.json()

  if (responseJson.email && !responseJson.verified) {
    if (
      window.location.href !==
      `${process.env.REACT_APP_WEB_URL}/a/verify-form/${responseJson.verificationToken}`
    ) {
      window.location.href = `${process.env.REACT_APP_WEB_URL}/a/verify-form/${responseJson.verificationToken}`
    }
  }

  currentUser.currentUser = responseJson.code === 401 ? { notLoaded: true } : responseJson

  setCurrentUser(currentUser)
}

export const checkLogin = (callback: any, fallback?: any) => {
  const { currentUser } = getCurrentUser()

  if (currentUser && !currentUser.notLoaded) {
    callback()
  } else {
    fallback()
  }
}
