/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import useValue, { UseValueCallbacks } from '../use-value'
import { Console } from 'console'

export type UseObjectCallbacks<X> = UseValueCallbacks<X> & {
  /** Updates value with overwritting previous value. Works with nested object properties */
  readonly update: (value: X) => any
  /**
   * Find path by `.` delimited key and update value
   * E.g .patch('obj.prop1.prop2', 'value')
   */
  readonly patch: (key: string, value: X) => void
  /**
   * Find property and output value
   * E.g .find('obj.prop1.prop2') // should output property value
   */
  readonly find: (key: string) => any
  /**
   * Find and remove property. Output removed property
   * E.g .find('obj.prop1.prop2') // should output property value
   */
  readonly remove: (key: string) => any
}

export type UseObject = <TYPE extends object>(
  initialValue?: TYPE,
  options?: {},
) => [TYPE, UseValueCallbacks<TYPE> & UseObjectCallbacks<TYPE>]

const divider = '.'
const split = (string: string) => {
  return [
    string.substring(0, string.indexOf(divider)),
    string.substring(string.indexOf(divider) + 1),
  ]
}

/**
 * useObject
 *
 * Returns a stateful object, and functions to interact with it
 */
const useObject: UseObject = initialValue => {
  const [value, $value] = useValue(initialValue)

  const update = (val: any) => {
    $value.set((prev: any) => {
      const deepMerge = (prev: any, val: any) => {
        const isObj = (elem: object) => elem && typeof elem === 'object'
        Object.keys(val).forEach(key => {
          // Check old/new obj if typeof is object then do deep merge
          // Otherwise use new obj to replace current
          if (prev && isObj(prev[key]) && isObj(val[key])) {
            prev[key] = deepMerge(prev[key], val[key])
          } else {
            prev = {
              ...prev,
              [key]: val[key],
            }
          }
        })
        return prev
      }

      return deepMerge(prev, val)
    })
  }

  const patch = (key: string, value: any) => {
    const patch = (key: string, obj: any = {}, value: any) => {
      // ['1', '2.3.4'] when input is '1.2.3.4'
      // ['', '1'] when input is '1'
      const parsed = split(key)

      if (parsed[0] && !obj[parsed[0]]) {
        obj[parsed[0]] = {}
      }

      if (parsed[1].includes('.')) {
        patch(parsed[1], obj[parsed[0]], value)
      } else {
        if (parsed[0]) {
          obj[parsed[0]] = {
            ...obj[parsed[0]],
            [parsed[1]]: value,
          }
        } else {
          obj[parsed[1]] = value
        }
      }
      return obj
    }

    $value.set((prev: any) => {
      return { ...patch(key, prev, value) }
    })
  }

  const find = (key: string): any => {
    const find = (key: string, obj: any = {}): any => {
      // ['1', '2.3.4'] when input is '1.2.3.4'
      // ['', '1'] when input is '1'
      const parsed = split(key)

      if (parsed[0] && !obj[parsed[0]]) {
        return undefined
      }

      if (parsed[1].includes('.')) {
        return find(parsed[1], obj[parsed[0]])
      } else {
        if (parsed[0]) {
          return obj[parsed[0]][parsed[1]]
        } else {
          return obj[parsed[1]]
        }
      }
    }

    return find(key, value)
  }

  const remove = (key: string): any => {
    const remove = (key: string, obj: any): any => {
      // ['1', '2.3.4'] when input is '1.2.3.4'
      // ['', '1'] when input is '1'
      const parsed = split(key)

      if (parsed[0] && !obj[parsed[0]]) {
        return undefined
      }

      if (parsed[1].includes('.')) {
        return remove(parsed[1], obj[parsed[0]])
      } else {
        if (parsed[0]) {
          delete obj[parsed[0]][parsed[1]]
        } else {
          delete obj[parsed[1]]
        }
      }
      return obj
    }

    $value.set((prev: any) => ({ ...remove(key, prev) }))
  }

  return [
    value,
    {
      ...$value,
      update,
      patch,
      find,
      remove,
    },
  ]
}

export default useObject
