/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const BASE_SIZE = 13
export const TEXT_RATIO = 0.09

export const buttonSizes: any = {
  xs: {
    fontSize: BASE_SIZE - BASE_SIZE * TEXT_RATIO * 2,
    boxSize: 24,
    iconSize: 10,
    padding: '0 8px',
    paddingIconLeft: '0 16px 0 2px',
    paddingIconRight: '0 2px 0 16px',
    shrink: 0.95,
  },
  sm: {
    fontSize: BASE_SIZE - BASE_SIZE * TEXT_RATIO,
    boxSize: 28,
    iconSize: 14,
    padding: '0 10px',
    paddingIconLeft: '0 20px 0 4px',
    paddingIconRight: '0 6px 0 28px',
    shrink: 0.95,
  },
  md: {
    fontSize: BASE_SIZE,
    boxSize: 32,
    iconSize: 12,
    padding: '0 12px',
    paddingIconLeft: '0 12px 0 8px',
    paddingIconRight: '0 8px 0 28px',
    shrink: 0.95,
  },
  lg: {
    fontSize: BASE_SIZE + BASE_SIZE * TEXT_RATIO,
    boxSize: 36,
    iconSize: 18,
    padding: '0 16px',
    paddingIconLeft: '0 16px 0 12px',
    paddingIconRight: '0 12px 0 16px',
    shrink: 0.95,
  },
  xl: {
    fontSize: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 2,
    boxSize: 42,
    iconSize: 24,
    padding: '0 20px',
    paddingIconLeft: '0 20px 0 16px',
    paddingIconRight: '0 16px 0 20px',
    shrink: 0.95,
  },
  default: {
    fontSize: 12,
    boxSize: 24,
    iconSize: 14,
  },
}
