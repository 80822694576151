/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
import { createContext } from 'react'

/* Common --------------------------------------------------------------------------------------- */
import { DEFAULT_CONFIG } from './config'
import { merge } from '../../share/obj-equal'

/* Types ---------------------------------------------------------------------------------------- */
export type ComponentKeys =
  | 'nav1'
  | 'nav2'
  | 'topbar'
  | 'header'
  | 'headerbar'
  | 'menubar'
  | 'toolbar'
  | 'sidebar'
  | 'sidebar2'
  | 'navbar'

type NavConfig = {
  min?: number
  max?: number
  allowPad?: boolean
  expand?: boolean
  disable?: boolean
  disableIcon?: boolean
  expandOnHover?: boolean
  iconOff?: any
  iconOn?: any
  iconBackgroundColor?: string
  iconColor?: string
}

type Nav2Config = NavConfig & {
  allowPad?: boolean
}

type SidebarConfig = Nav2Config & {
  contentWidth?: number
}

export type AppContext = {
  headerbarMove?: any
  sidebar1Move?: any
  sidebar2Move?: any
  nav2Hover?: any
  sidebar1Hover?: any
  sidebar2Hover?: any
  $headerbarMove?: any
  $sidebar1Move?: any
  $sidebar2Move?: any
  $nav2Hover?: any
  $sidebar1Hover?: any
  $sidebar2Hover?: any
  nav2?: any
  $nav2?: any
  config?: any
  components?: any
  $components?: any
  initialValues?: any
  topbar?: any
  $topbar?: any
  id?: any
  getOffsetTop?: any
}
const APP_LAYOUT_CONTEXT: AppContext = {
  config: null,
  headerbarMove: null,
  sidebar1Move: null,
  sidebar2Move: null,
  nav2Hover: null,
  sidebar1Hover: null,
  sidebar2Hover: null,
  $headerbarMove: null,
  $sidebar1Move: null,
  $sidebar2Move: null,
  $nav2Hover: null,
  $sidebar1Hover: null,
  $sidebar2Hover: null,
  nav2: null,
  $nav2: null,
  topbar: null,
  $topbar: null,
  getOffsetTop: () => { return 0 }
}

export default class AppLayoutClass1 {
  context: React.Context<AppContext>

  id?: string

  $navigation: any
  defaultNavigation: any
  getIsTopbarToggled?: any

  components: any
  props: any
  toggled: any

  toggleTopbar: any
  toggleNav1: any
  toggleNav2: any
  toggleHeaderbar: any
  toggleSidebar1: any
  toggleSidebar2: any

  activateBodyStyle: any

  config: any

  constructor(props?: { id?: string }) {
    this.id = props ? props.id : undefined
    this.toggled = {}
    this.components = {}
    this.props = {}
    this.config = DEFAULT_CONFIG
    this.context = createContext(APP_LAYOUT_CONTEXT)
  }

  setConfig(config: any) {
    this.config = merge(this.config, config)
  }

  setDefaultNavigation(navigation: any) {
    this.defaultNavigation = navigation
  }

  set: (key: ComponentKeys, component: any) => void = (key, component) => {
    this.components[key] = component
  }

  setComponent: (key: ComponentKeys, component: any) => void = (key, component) => {
    this.components[key] = component
  }

  setProps: (key: ComponentKeys, props: any) => void = (key, props) => {
    this.props[key] = { ...this.props[key], ...props }
  }

  isToggled = () => {
    return this.toggled
  }

  generateInitialConfig: (props: { key: string; config: any }) => any = ({ key, config }) => {
    if (typeof window !== 'undefined') {
      const local = window.localStorage
    }
  }

  getInitialValues(config: any) {
    return {
      // nav1: this.generateInitialConfig({ key: CONSTANTS.NAV1, config }),
      // nav2: this.generateInitialConfig({ key: CONSTANTS.NAV2, config }),
    }
  }
}
