import React from 'react'
import { classes, style, stylesheet } from 'typestyle'

/* Components =================================================================================== */
import { A } from '../../__aeki__/components/a'
import { Box } from '../../__aeki__/components/box/box.component'
import { mr16 } from '../../__aeki__/styles/styleset/margin/mr16'
import { useLocation } from 'react-router-dom'

export const Logo = (props: { scrolled?: boolean; withSmallLogo?: boolean }) => {
  const { scrolled } = props
  const location = useLocation()

  return (
    <A
      title={`Go to ${process.env.REACT_APP_PROJECT_NAME} Home`}
      to={location.pathname.includes('/en/') ? '/en/' : '/'}
      className={STYLES.logo}
    >
      <Box baseline="none" flex alignCenterY alignLeft>
        <LogoMain
          className={classes(mr16, style({ height: 24, transition: '0.2s ease-in-out' }))}
          toggled={scrolled}
        />
        {/* <LogoText
          className={style({ height: 20, transition: '0.2s ease-in-out' })}
          toggled={scrolled}
        /> */}
      </Box>
    </A>
  )
}

export const LogoSmall = null

const LogoMain = (props: { className?: string; toggled?: boolean }) => (
  <>
    <svg
      className={props.className}
      viewBox="0 0 95.41 19.39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <path
        d="M10.0015 32H0.00219727V15.68C1.14207 17.6359 2.90135 19.156 5.00185 20C11.0014 22 11.0014 28 10.0015 32Z"
        fill={props.toggled ? '#104e32' : 'white'}
      />
      <path
        d="M14.0007 0C14.0007 0 11.5209 21.14 11.3009 22.66C10.0957 20.4718 8.08811 18.837 5.70127 18.1C4.26807 17.5717 3.03219 16.6151 2.16152 15.36C1.71276 14.7238 1.31169 14.0554 0.961599 13.36C0.304168 11.9807 -0.0244195 10.4677 0.00166611 8.94C-0.0234736 7.65847 0.23616 6.38733 0.761833 5.21832C1.28751 4.04931 2.06611 3.01158 3.04145 2.18C4.71137 0.88645 6.73259 0.126696 8.84105 0C8.84105 0 14.0007 0 14.0007 0Z"
        fill={props.toggled ? '#104e32' : 'white'}
      />
      <path
        d="M32 0V15.2C31.6328 14.6692 31.2249 14.1677 30.7801 13.7C28.963 11.8181 26.8311 10.2681 24.4805 9.12L23.8206 8.8C22.3217 8.19199 20.9612 7.2872 19.8209 6.14C19.04 5.12456 18.5307 3.92689 18.341 2.66L18.001 1.02C17.9922 0.953624 17.9922 0.886375 18.001 0.82V0H32Z"
        fill={props.toggled ? '#104e32' : 'white'}
      />
      <path
        d="M32 20.2C32 20.4 32 20.6 32 20.78C32 20.96 32 21.02 32 21.12C32 21.22 32 21.78 32 22.1C31.9342 23.7893 31.4316 25.4327 30.5413 26.8699C29.6511 28.307 28.4035 29.4888 26.9203 30.3C25.3627 31.1633 23.6623 31.7392 21.9007 32V32L18.9609 8.26001C20.1487 9.20283 21.4526 9.98926 22.8406 10.6L23.5006 10.92C25.6255 11.9685 27.5537 13.3758 29.2002 15.08C30.6552 16.4447 31.6361 18.2386 32 20.2Z"
        fill={props.toggled ? '#104e32' : 'white'}
      />
      <path
        d="M16.0011 0L20.0008 32H12.0013L16.0011 0Z"
        fill={props.toggled ? '#e6c53b' : '#D5D5D5'}
      /> */}
      <g id="_레이어_1" data-name="레이어 1">
        <g>
          <path
            fill={props.toggled ? '#104e32' : 'white'}
            d="M0,6.29v5.77H14.68v1.05c0,.87-.71,1.57-1.57,1.57H0v4.72H13.1c3.47,0,6.29-2.82,6.29-6.29V7.34H4.72v-1.05c0-.87,.71-1.57,1.57-1.57h13.1V0H6.29C2.82,0,0,2.82,0,6.29Z"
          />
          <polygon
            fill={props.toggled ? '#104e32' : 'white'}
            points="27.77 0 20.36 19.39 25.57 19.39 31.27 4.45 36.98 19.39 42.18 19.39 34.78 0 27.77 0"
          />
          <polygon
            fill={props.toggled ? '#104e32' : 'white'}
            points="56.24 0 53.54 10.2 50.84 0 43.54 0 43.54 19.39 48.26 19.39 48.26 8.78 51.07 19.39 51.1 19.39 55.98 19.39 56.02 19.39 58.83 8.78 58.83 19.39 63.54 19.39 63.54 0 56.24 0"
          />
          <polygon
            fill={props.toggled ? '#104e32' : 'white'}
            points="80.74 0 76.02 0 76.02 19.39 95.41 19.39 95.41 14.68 80.74 14.68 80.74 0"
          />
          <rect
            fill={props.toggled ? '#104e32' : 'white'}
            className="cls-1"
            x="67.42"
            width="4.72"
            height="19.39"
          />
        </g>
      </g>
    </svg>
  </>
)

const LogoText = (props: { className?: string; toggled?: boolean }) => (
  <svg
    className={props.className}
    viewBox="0 0 144 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2948 1.5529L23.1019 13.2633L19.9517 16.1146L11.4485 7.56086L3.40638 16.8783L0 13.9252L11.2948 1.5529ZM3.61127 28.0541V18.5076H30.0427V31.6436H3.53444L3.61127 28.0541ZM25.2789 22.4535H8.27264V27.6722H25.2789V22.4535ZM25.2789 1.09467H30.0427V6.64439H33.7821V10.6667H30.0427V17.6165H25.2789V1.09467Z"
      fill={props.toggled ? 'black' : 'white'}
    />
    <path
      d="M47.1515 1.60383C49.6248 1.61913 52.0644 2.17538 54.2972 3.2331C55.4408 3.74389 56.3944 4.5983 57.0239 5.67606C57.6533 6.75383 57.9269 8.00067 57.806 9.24106C57.9196 10.4738 57.6424 11.7109 57.0132 12.7792C56.384 13.8474 55.4343 14.6932 54.2972 15.1981C52.0554 16.2205 49.618 16.7498 47.1515 16.7498C44.685 16.7498 42.2475 16.2205 40.0057 15.1981C38.8686 14.6932 37.9189 13.8474 37.2897 12.7792C36.6605 11.7109 36.3833 10.4738 36.4969 9.24106C36.376 8.00067 36.6496 6.75383 37.279 5.67606C37.9085 4.5983 38.8621 3.74389 40.0057 3.2331C42.2385 2.17538 44.6781 1.61913 47.1515 1.60383V1.60383ZM39.4679 28.105V23.0135H62.7235V21.5116H39.596V17.7184H67.5897V26.323H44.3598V27.7995H68.102V31.5672H39.6216L39.4679 28.105ZM47.1515 12.9324C48.5063 12.912 49.8448 12.6355 51.0957 12.1178C51.7068 11.914 52.2347 11.5182 52.5997 10.9901C52.9647 10.462 53.1469 9.83025 53.119 9.19015C53.1615 8.54412 52.9854 7.90254 52.6187 7.36745C52.2521 6.83235 51.716 6.43447 51.0957 6.23708C49.8497 5.72239 48.5136 5.45744 47.1643 5.45744C45.8149 5.45744 44.4789 5.72239 43.2328 6.23708C42.6125 6.43447 42.0764 6.83235 41.7098 7.36745C41.3432 7.90254 41.1671 8.54412 41.2095 9.19015C41.179 9.8343 41.3632 10.4705 41.7337 10.9998C42.1042 11.5292 42.6402 11.9222 43.2585 12.1178C44.4932 12.6298 45.8138 12.9062 47.1515 12.9324V12.9324ZM67.641 17.082H62.8259V1.04376H67.5641L67.641 17.082Z"
      fill={props.toggled ? 'black' : 'white'}
    />
    <path
      d="M91.6393 23.1917H86.9011V5.93159H73.4548V1.93477H91.6393V23.1917ZM104.445 31.9236H99.6046V1.06921H104.368L104.445 31.9236Z"
      fill={props.toggled ? 'black' : 'white'}
    />
    <path
      d="M141.096 17.6675H136.383V11.1249H131.107C130.869 12.1413 130.375 13.081 129.672 13.8558C128.969 14.6306 128.079 15.2151 127.086 15.5545C125.036 16.3054 122.868 16.6931 120.683 16.7001C118.217 16.6973 115.78 16.1677 113.537 15.1472C112.4 14.6423 111.451 13.7965 110.821 12.7283C110.192 11.66 109.915 10.4228 110.029 9.19014C109.919 7.95799 110.198 6.72243 110.827 5.65496C111.455 4.58748 112.403 3.74104 113.537 3.2331C115.747 2.18623 118.159 1.63026 120.606 1.60382C122.777 1.60867 124.93 2.00545 126.958 2.77486C127.948 3.11197 128.836 3.69261 129.539 4.46269C130.242 5.23278 130.737 6.16724 130.979 7.179H136.306V1.06921H141.096V17.6675ZM113.563 31.5163V18.5076H118.301V21.3333H136.358V18.5076H141.096V31.5672H113.691L113.563 31.5163ZM120.606 12.9324C121.961 12.912 123.3 12.6355 124.551 12.1177C125.162 11.914 125.69 11.5182 126.055 10.9901C126.42 10.462 126.602 9.83024 126.574 9.19014C126.616 8.54411 126.44 7.90253 126.074 7.36744C125.707 6.83234 125.171 6.43446 124.551 6.23707C123.305 5.72239 121.969 5.45743 120.619 5.45743C119.27 5.45743 117.934 5.72239 116.688 6.23707C116.063 6.42982 115.521 6.82573 115.149 7.36126C114.778 7.89679 114.598 8.54087 114.639 9.19014C114.608 9.8343 114.793 10.4705 115.163 10.9998C115.534 11.5292 116.07 11.9222 116.688 12.1177C117.93 12.633 119.26 12.9094 120.606 12.9324V12.9324ZM136.281 27.6213V25.3047H118.301V27.6213H136.281Z"
      fill={props.toggled ? 'black' : 'white'}
    />
  </svg>
)

const STYLES = stylesheet({
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
})
