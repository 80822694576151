/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
export const ZINDEX_0 = 0
export const ZINDEX_1 = 1
export const ZINDEX_2 = 2
export const ZINDEX_3 = 3
export const ZINDEX_4 = 4
export const ZINDEX_5 = 5
export const ZINDEX_6 = 6
export const ZINDEX_7 = 7
export const ZINDEX_8 = 8
export const ZINDEX_9 = 9
export const ZINDEX_10 = 10
export const ZINDEX_11 = 11
