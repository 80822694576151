/** 
 * Copyright 2021 AEKI <admin@aeki.dev>
 * 
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential 
 */
const divider = '.'
const split = (string: string) => {
  return [
    string.substring(0, string.indexOf(divider)),
    string.substring(string.indexOf(divider) + 1),
  ]
}

const DEFAULT_VALUES = {
  number: 0,
  boolean: false,
  array: [],
  object: {},
  string: '-',
  default: undefined,
}

const find = (key: string, obj: any = {}): any => {
  // ['1', '2.3.4'] when input is '1.2.3.4'
  // ['', '1'] when input is '1'
  const parsed = split(key)

  if (parsed[0] && !obj[parsed[0]]) {
    return undefined
  }

  if (parsed[1].includes('.')) {
    return get(parsed[1], obj[parsed[0]])
  } else {
    if (parsed[0]) {
      return obj[parsed[0]][parsed[1]]
    } else {
      return obj[parsed[1]]
    }
  }
}

const handleDefault = (defaultValues: any, type?: string) => {
  switch (type) {
    case 'number':
      return defaultValues.number
    case 'boolean':
      return defaultValues.boolean
    case 'array':
      return defaultValues.array
    case 'object':
      return defaultValues.object
    case 'string':
      return defaultValues.string
    default:
      return defaultValues.default
  }
}

export const get = (
  key: string,
  value: any,
  type?: 'number' | 'string' | 'boolean' | 'array' | 'object',
  defaultValue?: any,
): any => {
  try {
    const found = find(key, value)
    if (found !== undefined && found != null && found !== 'null') {
      switch (type) {
        case 'number':
          return Number(found)
        case 'boolean':
          return found === 'true' || found === true
        default:
          return found
      }
    } else {
      return defaultValue !== undefined ? defaultValue : handleDefault(DEFAULT_VALUES, type)
    }
  } catch (e) {
    return defaultValue !== undefined ? defaultValue : handleDefault(DEFAULT_VALUES, type)
  }
}

export const set = (key: string, obj: any, value: any) => {
  const parsedKey = key.split('.')
  parsedKey.reduce((a, b, level) => {
    if (typeof a[b] === "undefined" && level !== key.length) {

      a[b] = {};
      return a[b];
    }

    if (level === parsedKey.length - 1) {
      a[b] = value;
      return value;
    }
    return a[b];
  }, obj)
}

export default (defaultValues: {
  number?: number
  boolean: boolean
  array: any[]
  default: any
}) => (
  key: string,
  value: any,
  type?: 'number' | 'string' | 'boolean' | 'array' | 'object',
  ): any => {
    const _DEFAULT_VALUES = { ...DEFAULT_VALUES, ...defaultValues }

    try {
      const found = find(key, value)
      if (found !== undefined && found != null && found !== 'null') {
        switch (type) {
          case 'number':
            return Number(found)
          case 'boolean':
            return found === 'true'
          default:
            return found
        }
      } else {
        return handleDefault(_DEFAULT_VALUES, type)
      }
    } catch (e) {
      return handleDefault(_DEFAULT_VALUES, type)
    }
  }
