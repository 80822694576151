import React from 'react'
import { classes, style } from 'typestyle'
import { KakaoLoader } from '../../components/kakao'
import { KakaoChannelButton } from '../../components/kakao-channel-button'
import { mr12 } from '../../__aeki__/styles/styleset/margin/mr12'
import { mr16 } from '../../__aeki__/styles/styleset/margin/mr16'
import { mr8 } from '../../__aeki__/styles/styleset/margin/mr8'
import { mr8sm } from '../../__aeki__/styles/styleset/margin/mr8sm'

const loc = 'en'
const locale = {
  company: {
    en: 'About us',
    ko: '회사소개',
  },
  services: {
    en: 'Services',
    ko: '사업영역',
  },
  contactUs: {
    en: 'Contact Us',
    ko: '문의하기',
  },
}

export const HEADER_PROPS = {
  hadow: false,
  baseline: 'surface',
  disableUserMenu: true,
  scrollEffect: true,
  toggled: false,
  menu: [
    {
      label: locale.company[loc],
      to: '/a/company',
      baseline: 'none',
      buttonProps: (scrolled: boolean) => {
        if (scrolled) {
          return {
            className: classes(style({ color: 'black !important' }), mr12),
          }
        }

        return {
          className: classes(style({ color: 'white !important' }), mr12),
        }
      },
    },
    {
      label: locale.services[loc],
      to: '/a/services',
      baseline: 'none',
      buttonProps: (scrolled: boolean) => {
        if (scrolled) {
          return {
            className: mr12,
          }
        }

        return {
          className: classes(style({ color: 'white !important' }), mr12),
        }
      },
    },
    {
      label: locale.contactUs[loc],
      to: '/a/contact-us',
      // isPrimary: true,
      baseline: 'primary',
      buttonProps: (scrolled: boolean) => {
        if (scrolled) {
          return {
            square: false,
            className: classes(mr12, mr8sm),
          }
        }

        return {
          square: false,
          className: classes(style({ color: 'white !important' }), classes(mr12, mr8sm)),
        }
      },
    },
    {
      isPrimary: true,
      component: <KakaoLoader component={KakaoChannelButton} />,
    },
  ],
}
